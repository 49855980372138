<mat-drawer-container class="editing-sidebar">
  <mat-drawer mode="over" #drawer position="end" class="editing-sidebar-drawer-content">
    <ng-container *ngIf="activeModule === 'addingPrey'">
      <ng-container *ngTemplateOutlet="preyAddContent"></ng-container>
    </ng-container>

    <ng-container *ngIf="activeModule === 'updatingPrey'">
      <ng-container *ngTemplateOutlet="preyUpdateContent"></ng-container>
    </ng-container>
  </mat-drawer>
  <mat-drawer-content>
    <div class="content-container">
      <div class="content-header">
        <button mat-button (click)="startAdd()"><mat-icon>add</mat-icon>Create</button>
      </div>
      <mat-divider></mat-divider>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">

          <!-- Position Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element"> {{element.displayName}} </td>
          </ng-container>
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>Update</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="startUpdate(element)"><mat-icon>edit</mat-icon></button>
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="deleteTarget(element)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]"
                       showFirstLastButtons
                       aria-label="Select page of population prey">
        </mat-paginator>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>




<ng-template #preyAddContent>
  <div class="content" *ngIf="newPrey !== undefined">
    <mat-form-field color="accent">
      <mat-label>Prey Name</mat-label>
      <input matInput [(ngModel)]="newPrey.displayName" />
    </mat-form-field>

    <div class="button-row">
      <button mat-button (click)="drawer.close()">Cancel</button>
      <button mat-button (click)="createTarget()">Save</button>
    </div>

  </div>
</ng-template>

<ng-template #preyUpdateContent>
  <div class="content" *ngIf="newPrey !== undefined">
    <mat-form-field color="accent">
      <mat-label>Prey Name</mat-label>
      <input matInput [(ngModel)]="newPrey.displayName" />
    </mat-form-field>

    <div class="button-row">
      <button mat-button (click)="drawer.close()">Cancel</button>
      <button mat-button (click)="updateTarget(newPrey)">Save</button>
    </div>

  </div>
</ng-template>

<!--<mat-card class="card">-->
<!--    <mat-card-header>-->
<!--      <mat-card-title>Prey Targets</mat-card-title>-->
<!--    </mat-card-header>-->

<!--    <mat-card-content *ngIf="prey && fetched">-->
<!--      <div class="prey" *ngFor="let p of prey" (click)="activateAdding(p.id!)">-->
<!--        <h4 *ngIf="!editing!.get(p.id!)!">{{p.displayName}}</h4>-->
<!--        <div class="editing-box" *ngIf="editing!.get(p.id!)">-->
<!--          <mat-form-field class="full-width">-->
<!--            <mat-label>Name</mat-label>-->
<!--            <input matInput [(ngModel)]="p!.displayName" />-->
<!--          </mat-form-field>-->
<!--          <button mat-button (click)="updateTarget(p)">Submit</button>-->
<!--          <button mat-button color="warn" (click)="editing!.set(p.id!, false)">Cancel</button>-->
<!--          <button mat-button color="warn" (click)="deleteTarget(p)">Delete</button>-->
<!--        </div>-->
<!--        <mat-divider></mat-divider>-->
<!--      </div>-->

<!--      <div class="adding-container" *ngIf="adding">-->
<!--        <mat-form-field class="full-width">-->
<!--          <mat-label>Name</mat-label>-->
<!--          <input matInput [(ngModel)]="newPrey!.displayName"/>-->
<!--        </mat-form-field>-->
<!--        <button mat-button (click)="createTarget()">Submit</button>-->
<!--        <button mat-button color="warn" (click)="cancelAdding()">Cancel</button>-->
<!--      </div>-->
<!--    </mat-card-content>-->
<!--    <mat-card-actions>-->
<!--      <button mat-button (click)="addPreyTarget()" *ngIf="!adding">Add Prey Target</button>-->
<!--    </mat-card-actions>-->
<!--    <mat-card-footer *ngIf="!fetched">-->
<!--      <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>-->
<!--    </mat-card-footer>-->
<!--  </mat-card>-->


