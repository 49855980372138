import {Component, OnInit, ViewChild} from '@angular/core';
import {UserManagementService} from "../../../services/user/management/user-management.service";
import {UserViewResponseDto} from "../../../models/dto/response/user/management/userViewResponseDto";
import {UserDto} from "../../../models/dto/response/user/management/userDto";
import { MatAccordion } from '@angular/material/expansion';
import {RolesRequestResponseDto} from "../../../models/dto/response/user/management/rolesRequestResponseDto";
import {RoleUpdateResponseDto} from "../../../models/dto/response/user/management/roleUpdateREsponseDto";
import {ILoggingService} from "../../../services/logging/logging.service.interface";
import {ResponseDto} from "../../../models/dto/response/responseDto";
import {HttpErrorResponse} from "@angular/common/http";
import {
  NewSubmissionUserDialogComponent
} from "../../fin-print/submit-data/dialogs/newSubmissionUserDialog/new-submission-user-dialog.component";
import {AuthenticationService} from "../../../services/user/authentication.service";
import {RegistrationResponseDto} from "../../../models/dto/response/user/registrationResponseDto";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {MatDrawer} from "@angular/material/sidenav";
import {FormControl} from "@angular/forms";


@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {
  public users: Array<UserDto> | undefined;
  public roles: Array<string> | undefined;
  public roleUpdates: Map<string, boolean> = new Map<string, boolean>();
  public buttonLoads: Map<string, boolean> = new Map<string, boolean>();
  public saving = false;

  public displayedColumns = ['firstName', 'lastName', 'email', 'encounters', 'images', 'role', 'update', 'delete']
  public dataSource: Array<UserDto> = [];
  public activeElement: UserDto | undefined;

  @ViewChild(MatAccordion) accordion!: MatAccordion;
  @ViewChild(MatDrawer) drawer!: MatDrawer;

  public drawerOpen: boolean = false;
  public activeModule: string = "roleUpdate";
  public roleFormControl = new FormControl("");
  constructor(
    private userManagementService: UserManagementService,
    private logService: ILoggingService,
    private dialog: MatDialog,
    private authService: AuthenticationService,
    private router: Router
    ) { }

  ngOnInit(): void {
    if (!this.authService.isUserAdmin()) {
      this.router.navigate(['/forbidden'])
    }
    this.userManagementService.getUsers()
      .subscribe( (response: UserViewResponseDto) => {
        this.users = response.users;
        this.dataSource = response.users;
        for(let user of this.users) {
          this.roleUpdates.set(user.email, false);
          this.buttonLoads.set(user.email, false);
        }
    })
    this.userManagementService.getRoles()
      .subscribe( (response: RolesRequestResponseDto) => {
        this.roles = response.roles;
      })
  }

  public getJdenticon(user: UserDto) {
    return `${user.firstName} ${user.lastName} ${user.email}`;
  }
  public roleUpdatingActive(user: UserDto) {
    return this.roleUpdates.get(user.email);
  }

  public activateRoleUpdate(user: UserDto) {
    this.roleUpdates.set(user.email, true);
  }

  public deactivateRoleUpdate(user: UserDto) {
    this.roleUpdates.set(user.email, false);
  }

  public saveRoleUpdate(user: UserDto) {
    // @ts-ignore
    user.roles = [user.systemRole];
    this.buttonLoads.set(user.email, true);
    this.saving = true;
    this.userManagementService.updateRoles(user)
      .subscribe( (response: RoleUpdateResponseDto) => {
        if (response.updateSuccessful) {
          this.logService.info("Role update successful", true);
        }
        this.buttonLoads.set(user.email, false);
        this.saving = false;
    })
  }

  public loadButtonActive(user: UserDto) {
    return this.buttonLoads.get(user.email);
  }

  public deleteUser(user: UserDto) {
    this.saving = true;
    this.userManagementService.deleteUser(user.email).subscribe({
      next: (response: ResponseDto) => {
        this.logService.info(`${user.firstName} ${user.lastName} and their entries were removed.`, true);
        this.saving = false;
        this.ngOnInit();
      },
      error: (error: HttpErrorResponse) => {
        this.logService.error(`Error removing user: ${error.message}`, true)
        this.saving = false;
      }
    })
  }

  createUser() {
    this.saving = false;
    const dialogRef = this.dialog.open(NewSubmissionUserDialogComponent, {
      data: {
        firstName: "",
        lastName: "",
        email: "",
        informUser: false,
        users: []
      },
      disableClose: true
    });
    dialogRef
      .afterClosed()
      .subscribe( data => {
        if (data && data.firstName && data.lastName && data.email) {
          this.authService.createUser(data).subscribe({
            next: (value: RegistrationResponseDto) => {
              this.logService.info(`${data.firstName} ${data.lastName} successfully created`, true);
              this.saving = true;
              this.ngOnInit();
            },
            error: (value: HttpErrorResponse) => {
              this.logService.error(`Could not create user: ${value.message}`, true);
              this.saving = true;
            }
          })
        }

      });
  }

  getProfileLink(user: UserDto) {
    return `/users/${user.id}`;
  }

  sort(users: Array<UserDto>) {
    return users.sort((a, b) => (a.lastName ?? "").localeCompare(b.lastName!));
  }

  doRoleUpdate(element: UserDto) {
    this.activeModule = 'roleUpdate';
    element.systemRole = element.roles ? element.roles[0] : this.roles![0];
    this.activeElement = element;
    this.drawer.open();
    // const ref = this.dialog.open(UserRoleUpdateDialogComponent, {
    //   data: {
    //     user: element,
    //     roles: this.roles
    //   }
    // });
  }
}
