<div class="form-content-container">
  <mat-card class="login-card" *ngIf="loginActive">
    <mat-card-title><h3>Log In</h3></mat-card-title>
    <mat-card-content>
      <form class="login-form form" [formGroup]="loginForm" (ngSubmit)="loginUser(loginForm.value)">
        <mat-form-field color="accent" class="full-width">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Email" formControlName="email">
        </mat-form-field>
        <mat-form-field  color="accent" class="full-width">
          <mat-label>Password</mat-label>
          <input matInput placeholder="Password" type="password" formControlName="password" (keyup.enter)="loginUser(loginForm.value)">
        </mat-form-field>
      </form>
      <div class="login-errors" *ngIf="showLoginError">
        <mat-error>{{loginErrorMessage}}</mat-error>
      </div>
    </mat-card-content>
    <mat-card-actions class="button-row">
      <button mat-button color="accent" (click)="loginUser(loginForm.value)" class="button-with-spinner">
        <div class="button-content">
          <span *ngIf="!loading">Sign In</span>
          <span *ngIf="loading" class="login-spinner-container"><mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" [strokeWidth]="2" color="accent"></mat-progress-spinner>
        </span>
        </div>
      </button>
      <div class="secondary-button-group">
        <a mat-button color="accent" [routerLink]="['/forgotpassword']"> <span>Forgot Password?</span> </a>
<!--        <button mat-button color="accent" (click)="loginActive = !loginActive">Don't have an account? Sign Up!</button>-->
      </div>

    </mat-card-actions>
  </mat-card>
<!--  <mat-card class="login-card" *ngIf="!loginActive">-->
<!--    <mat-card-title>Sign Up</mat-card-title>-->
<!--    <mat-card-content>-->
<!--      <form class="signup-form form" [formGroup]="registerForm" (ngSubmit)="registerUser(registerForm.value)">-->
<!--        <mat-form-field >-->
<!--          <mat-label>First Name</mat-label>-->
<!--          <input matInput placeholder="First Name" formControlName="firstName">-->
<!--        </mat-form-field>-->

<!--        <mat-form-field >-->
<!--          <mat-label>Last Name</mat-label>-->
<!--          <input matInput placeholder="Last Name" formControlName="lastName">-->
<!--        </mat-form-field>-->

<!--        <mat-form-field >-->
<!--          <mat-label>Email</mat-label>-->
<!--          <input type="email" matInput formControlName="email" [errorStateMatcher]="matcher"-->
<!--                 placeholder="Ex. pat@example.com">-->
<!--          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">-->
<!--            Please enter a valid email address-->
<!--          </mat-error>-->
<!--          <mat-error *ngIf="emailFormControl.hasError('required')">-->
<!--            Email is <strong>required</strong>-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->

<!--        <mat-form-field >-->
<!--          <mat-label>Password</mat-label>-->
<!--          <input matInput placeholder="Password" type="password" formControlName="password">-->
<!--        </mat-form-field>-->
<!--        <mat-form-field >-->
<!--          <mat-label>Repeat Password</mat-label>-->
<!--          <input matInput placeholder="Repeat Password" type="password" formControlName="confirm"  (keyup.enter)="registerUser(registerForm.value)">-->
<!--          <mat-error *ngIf="registerForm.get('confirm')?.invalid">-->
<!--            Passwords must match-->
<!--          </mat-error>-->
<!--        </mat-form-field>-->
<!--        <div class="registration-errors">-->
<!--          <mat-list>-->
<!--            <mat-list-item *ngFor="let registrationError of registrationErrors">-->
<!--              {{registrationError}}-->
<!--            </mat-list-item>-->
<!--          </mat-list>-->
<!--        </div>-->
<!--      </form>-->
<!--    </mat-card-content>-->
<!--    <mat-card-actions>-->
<!--      <button mat-button color="primary" [disabled]="!registerForm.valid" (click)="registerUser(registerForm.value)"  class="button-with-spinner">-->
<!--        <div class="button-content">-->
<!--          <span>Register</span>-->
<!--          <span *ngIf="loading" class="login-spinner-container"><mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" [strokeWidth]="2"></mat-progress-spinner>-->
<!--        </span>-->
<!--        </div>-->
<!--      </button>-->
<!--      <button mat-button (click)="loginActive = !loginActive">Already have an account? Sign In!</button>-->
<!--    </mat-card-actions>-->
<!--  </mat-card>-->
</div>



