<mat-drawer-container class="editing-sidebar">
  <mat-drawer mode="over" #drawer position="end" class="editing-sidebar-drawer-content">
    <h4>Updating Role for {{activeElement?.userProfileDto?.firstName}} {{activeElement?.userProfileDto?.lastName}}</h4>
    <div class="content" *ngIf="activeElement && populationRoles">
      <mat-select [(ngModel)]="activeElement.roleName" (ngModelChange)="activeElement.roleUpdated = true">
        <mat-option *ngFor="let role of populationRoles" [value]="role.normalizedName">{{role.name}} </mat-option>
      </mat-select>
    </div>
    <div class="button-row">
      <button mat-button>Cancel</button>
      <button mat-button>Save</button>
    </div>

<!--    <ng-container *ngIf="activeModule === 'addingBehavior'">-->
<!--      <ng-container *ngTemplateOutlet="behaviorAddContent"></ng-container>-->
<!--    </ng-container>-->

<!--    <ng-container *ngIf="activeModule === 'updatingBehavior'">-->
<!--      <ng-container *ngTemplateOutlet="behaviorUpdateContent"></ng-container>-->
<!--    </ng-container>-->
  </mat-drawer>
  <mat-drawer-content>
    <div class="content-container">
      <div class="content-header">
        <button mat-button (click)="startAdd()"><mat-icon>add</mat-icon>Invite</button>
      </div>
      <mat-divider></mat-divider>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="profile">
            <th mat-header-cell *matHeaderCellDef>Profile</th>
            <td mat-cell *matCellDef="let element">
              <a [routerLink]="getProfileLink(element)" mat-icon-button><mat-icon>account_circle</mat-icon></a>
            </td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              <a (click)="startEdit(element)">{{element.userProfileDto.firstName}} {{element.userProfileDto.lastName}}</a>
            </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element"> {{element.userProfileDto.email}} </td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let element"> {{element.roleName}} </td>
          </ng-container>
          <ng-container matColumnDef="joined">
            <th mat-header-cell *matHeaderCellDef>Date Joined</th>
            <td mat-cell *matCellDef="let element"> {{getDateString(element.memberSince)}} </td>
          </ng-container>
          <ng-container matColumnDef="confirmed">
            <th mat-header-cell *matHeaderCellDef>Confirmed</th>
            <td mat-cell *matCellDef="let element">
              <mat-icon *ngIf="element.confirmed">check_circle</mat-icon>
              <button *ngIf="!element.confirmed" mat-button (click)="confirmPopulationMember(element)"><mat-icon>add_circle</mat-icon>Confirm</button>
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="deleteUser(element.id)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]"
                       showFirstLastButtons
                       aria-label="Select page of population behavior">
        </mat-paginator>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<div class="section-header">

  <span class="spacer"></span>
  <button mat-stroked-button color="accent" (click)="openMemberAddition()"><mat-icon>person</mat-icon>Add Member</button>
</div>
<div *ngIf="populationMembers; else loading">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let member of populationMembers" class="population-member">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="title-header">
            {{member.userProfileDto?.firstName}} {{member.userProfileDto?.lastName}}
          </div>
          <div class="title-subheader">
            <span class="role" *ngIf="member.roleName !== 'Novice'"> ({{member.roleName}})</span>
            <span class="new-role-status" *ngIf="!member.confirmed">New Member
                      <span class="member-since" *ngIf="member.memberSince !== undefined">
                         (Since {{getDateString(member.memberSince)}})
                      </span>
                    </span>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="population-user-content">
        <h4 *ngIf="member.memberSince !== undefined">Member Since: {{getDateString(member.memberSince)}}</h4>
        <h4><a [routerLink]="['/users', member.userProfileDto!.id!]">Profile</a></h4>
      </div>
      <mat-divider></mat-divider>
      <div class="population-user-role-update" *ngIf="populationRights !== undefined && (populationRights.professional  || populationRights.administrator)">
        <div class="population-user-confirm" *ngIf="!member.confirmed">
          <button mat-stroked-button color="accent" (click)="confirmPopulationMember(member)">Confirm {{member.userProfileDto!.firstName}} {{member.userProfileDto!.lastName}}</button>
        </div>

        <div class="population-use-role-change" *ngIf="member.confirmed">
          <h3>Update User Role</h3>
          <mat-radio-group
            class="role-radio-group"
            [(ngModel)]="member.roleName"
            (ngModelChange)="member.roleUpdated = true"
            *ngIf="populationRoles as roles"
          >
            <mat-radio-button class="example-radio-button" *ngFor="let role of roles" [value]="role.normalizedName">
              {{role.name}}
            </mat-radio-button>
          </mat-radio-group>
          <button mat-stroked-button color=accent *ngIf="member.roleUpdated" (click)="updateRole(member)">Change Role to {{member.roleName}}</button>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<ng-template #loading>
  <h4>Getting population members...</h4>
</ng-template>
