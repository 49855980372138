<div class="encounter-sidebar-container" *ngIf="dto !== undefined">
  <div class="text-details">

  <div class="encounter-title-container">
    <h2>{{formatDate(dto.dateTime)}}</h2>
    <h2 *ngIf="dto.location">{{dto.location.name}}</h2>
    <h2 *ngIf="dto.user" class="pointer" [routerLink]="getUserLink(dto.user)">{{dto.user.firstName}} {{dto.user.lastName}}</h2>
    <div class="organizations" *ngIf="dto.organizations">
      <div class="organization pointer" *ngFor="let org of dto.organizations" [routerLink]="getOrgLink(org)">
        <h3>{{org.name}}</h3>
      </div>
    </div>
    <div class="license">
      <div class="license-logos">
        <span class="license-logo" *ngFor="let logo of getLicenseLogos()" [matTooltip]="logo.text"  matTooltipPosition="after">
          <a [href]="logo.link" target="_blank" >
              <img [src]="logo.img" loading="lazy" [alt]="logo.text"/>
          </a>
        </span>
      </div>
    </div>
    <div class="archived" *ngIf="dto.archived">
      <p>Marked as Archived <span *ngIf="dto.archivedOn">on {{dateService.formatDateFromAny(dto.archivedOn, false, true, true, true, false)}} </span> <span *ngIf="dto.archivedBy"> by {{dto.archivedBy!.firstName}} {{dto.archivedBy!.lastName}}</span></p>
    </div>
  </div>
<!--    <mat-divider></mat-divider>-->
<!--    <div class="encounter-next-prev button-row">-->
<!--      <button class="encounter-prev" mat-button (click)="previousEncounter()"><mat-icon>chevron_left</mat-icon><span> Previous Encounter</span></button>-->
<!--      <button class="encounter-next" mat-button (click)="nextEncounter()"><span>Next Encounter</span> <mat-icon>chevron_right</mat-icon></button>-->
<!--    </div>-->
  <mat-divider></mat-divider>
  <app-encounter-flags [encounter]="dto" [workspace]="workspace"></app-encounter-flags>
  <app-encounter-completion-status [encounter]="dto" [workspace]="workspace"></app-encounter-completion-status>
  <app-encounter-predation [encounter]="dto" [workspace]="workspace"></app-encounter-predation>
  <div class="behaviors" *ngIf="dto.behaviors && dto.behaviors.length > 0">
    <h2>Observed Behaviors</h2>
    <mat-list>
      <mat-list-item *ngFor="let behavior of dto.behaviors">{{behavior.description}}</mat-list-item>
    </mat-list>
    <mat-divider></mat-divider>
  </div>
  <app-encounter-notes [encounter]="dto" [workspace]="workspace" [internal]="false" title="Encounter Notes"></app-encounter-notes>
  <app-encounter-notes [encounter]="dto" [workspace]="workspace" [internal]="true" title="Analyst Notes" *ngIf="internalActive"></app-encounter-notes>
  <div class="encounter-details-container" *ngIf="dto.preview">
    <h3>{{dto.preview.imageCount}} Images</h3>
<!--    <h3>{{dto.preview.detectionCount}} Detections</h3>-->
    <div class="animals" *ngIf="dto.preview.animals && dto.preview.animals.length > 0">
      <h3>Animals</h3>
      <div class="animal-list">
        <div class="animal hil" *ngFor="let animal of animalService.sortFamilies(dto.preview.animals)" [routerLink]="getAnimalLink(animal)">{{animal.identifier}}</div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  </div>
  <div class="map-container" *ngIf="dto.location">
    <animal-track-select-map [singleSelect]="true"
                             [allowDrag]="false"
                             [selectedLocation]="dto.location"
                             [allowReselect]="false"
                             [initialZoom]="8"
    ></animal-track-select-map>
  </div>

</div>
