<mat-drawer-container class="editing-sidebar">
  <mat-drawer mode="over" #drawer position="end" class="editing-sidebar-drawer-content">
<!--    <ng-container *ngIf="activeModule === 'addingBehavior'">-->
<!--      <ng-container *ngTemplateOutlet="behaviorAddContent"></ng-container>-->
<!--    </ng-container>-->

<!--    <ng-container *ngIf="activeModule === 'updatingBehavior'">-->
<!--      <ng-container *ngTemplateOutlet="behaviorUpdateContent"></ng-container>-->
<!--    </ng-container>-->
    <div class="content" *ngIf="activeElement">
      <mat-form-field color="accent">
        <mat-label>Name</mat-label>
        <input matInput [(ngModel)]="activeElement.name" />
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Group</mat-label>
        <input matInput [(ngModel)]="activeElement.group" />
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Role</mat-label>
        <input matInput [(ngModel)]="activeElement.role" />
      </mat-form-field>
      <mat-form-field color="accent">
        <mat-label>Location</mat-label>
        <input matInput [(ngModel)]="activeElement.location" />
      </mat-form-field>

      <mat-form-field color="accent">
        <mat-label>Contact</mat-label>
        <input matInput [(ngModel)]="activeElement.contact" />
      </mat-form-field>

      <mat-form-field color="accent">
        <mat-label>Link</mat-label>
        <input matInput [(ngModel)]="activeElement.link" />
      </mat-form-field>

      <mat-divider></mat-divider>
      <div class="logo-selection">
        <h4>Contributor Logo</h4>
        <input type="file"  class="file-upload" (change)="addIcon($event)">
        <div *ngIf="activeElement.icon" class="logo-display">
          <img [src]="activeElement.icon" [alt]="activeElement.name"/>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="logo-selection" *ngIf="activeElement.footer">
        <h4>Footer Logo</h4>
        <input type="file"  class="file-upload" (change)="addAlternateIcon($event)">
        <div *ngIf="activeElement.footerIcon" class="logo-display">
          <img [src]="activeElement.footerIcon" [alt]="activeElement.name"/>
        </div>
      </div>
    </div>
    <div class="button-row">
      <button mat-button (click)="cancel()">Cancel</button>
      <button mat-button (click)="saveContributor(activeElement)">Save</button>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="content-container">
      <div class="content-header">
        <button mat-button (click)="addContributor()"><mat-icon>add</mat-icon>Create</button>
      </div>
      <mat-divider></mat-divider>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">

          <ng-container matColumnDef="logo">
            <th mat-header-cell *matHeaderCellDef>Logo</th>
            <td mat-cell *matCellDef="let element">
              <img class="table-icon" *ngIf="element.icon" [src]="element.icon" [alt]="element.name"/>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">
              <a (click)="startEdit(element)">{{element.name}}</a>
            </td>
          </ng-container>
          <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef>Group</th>
            <td mat-cell *matCellDef="let element"> {{element.group}} </td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>Role</th>
            <td mat-cell *matCellDef="let element"> {{element.role}} </td>
          </ng-container>
          <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef>Location</th>
            <td mat-cell *matCellDef="let element"> {{element.location}} </td>
          </ng-container>
          <ng-container matColumnDef="contact">
            <th mat-header-cell *matHeaderCellDef>Contact</th>
            <td mat-cell *matCellDef="let element">
              {{element.contact}}
            </td>
          </ng-container>
          <ng-container matColumnDef="link">
            <th mat-header-cell *matHeaderCellDef>Link</th>
            <td mat-cell *matCellDef="let element">
              <a [href]="element.link"  target="_blank" mat-icon-button><mat-icon>exit_to_app</mat-icon></a>
            </td>
          </ng-container>

          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="deleteContributor(element)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]"
                       showFirstLastButtons
                       aria-label="Select page of population behavior">
        </mat-paginator>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<h3 class="title">Population Contributors <button mat-icon-button (click)="addContributor()" *ngIf="!addingContributor"><mat-icon>add</mat-icon></button></h3>
<div class="contributor-content"  *ngIf="contributorsObs | async as contributors else loading">
  <div class="new-contributor" *ngIf="addingContributor && contributor">
    <app-population-contributor-edit [population]="population" [contributor]="contributor" [contributors]="contributors" (cancelled)="cancel()" [editing]="false"></app-population-contributor-edit>
  </div>
  <div class="contributor-list">
    <mat-card class="contributor" *ngFor="let c of sortContributors(contributors)">
      <mat-card-header>
        <mat-card-title>{{c.name}}</mat-card-title>
        <mat-card-subtitle>{{c.contact}}</mat-card-subtitle>

      </mat-card-header>
      <mat-card-content *ngIf="editingContributorId !== c.id">
        <h4>{{c.name}}</h4>
        <h4>{{c.group}}</h4>
        <h4>{{c.role}}</h4>
        <h4>{{c.location}}</h4>
        <h4>{{c.contact}}</h4>
        <h4>{{c.link}}</h4>
        <div *ngIf="c.icon" class="logo-display">
          <img [src]="c.icon" [alt]="c.name"/>
        </div>

        <div *ngIf="c.footerIcon" class="logo-display">
          <img [src]="c.footerIcon" [alt]="c.name"/>
        </div>
      </mat-card-content>
      <mat-card-content *ngIf="editingContributorId === c.id">
        <app-population-contributor-edit [population]="population" [contributor]="c" [contributors]="contributors" [editing]="true" (cancelled)="cancel()"></app-population-contributor-edit>

      </mat-card-content>
      <mat-card-actions *ngIf="editingContributorId !== c.id">
        <button mat-button (click)="editContributor(c.id!)">Edit Contributor Data</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

<ng-template #loading>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" [color]="'accent'"></mat-progress-spinner>
</ng-template>
