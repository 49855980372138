<mat-toolbar color="primary" class="desktop-menu"  *ngIf="!mobileMenu">
  <mat-toolbar-row>
    <div class="main-left">
      <a [routerLink]="'/'" mat-button class="app-title">
        <img [src]="logo" [alt]="title" loading="lazy"/>
      </a>
    </div>
    <div class="nav-entries" *ngIf="authService.isUserAuthenticated() && !loading">
      <global-population-select [auth]="authService.isUserAuthenticated()" (populationChanged)="switchWorkspace($event)"></global-population-select>
      <div class="side-bar-main-action" *ngFor="let link of primaryEntries">
        <a mat-button [routerLink]="link.url">
          {{ link.displayText }}
        </a>
      </div>
      <div class="side-bar-main-action" *ngFor="let link of secondaryEntries">
        <a mat-button [routerLink]="link.url">
          {{ link.displayText }}
        </a>
      </div>
    </div>
    <mat-progress-spinner *ngIf="loading && authService.isUserAuthenticated()" diameter="40" color="accent" mode="indeterminate"></mat-progress-spinner>

  <div class="user-entries">
    <app-notification-dropdown (notificationsFetched)="notificationsFetched.emit($event)" [user]="user" [inputSideNav]="inputSideNav" *ngIf="authService.isUserAuthenticated()"></app-notification-dropdown>
    <a mat-button [routerLink]="'login'" *ngIf="!authService.isUserAuthenticated()">Log In</a>
    <app-user-menu [user]="user" [userMenuEntries]="userMenuEntries" (userLoggedOut)="logout()" *ngIf="authService.isUserAuthenticated()"></app-user-menu>
  </div>

  </mat-toolbar-row>

</mat-toolbar>



<mat-toolbar color="primary" class="mobile-menu" *ngIf="mobileMenu">
  <mat-toolbar-row>
    <div class="main-left">
      <a [routerLink]="'/'" mat-button class="app-title">
        <img [src]="logo" [alt]="title" loading="lazy"/>
      </a>
    </div>

    <div class="user-entries">
      <a mat-button [routerLink]="'login'" *ngIf="!authService.isUserAuthenticated()">Log In</a>
      <app-user-menu [user]="user" [userMenuEntries]="userMenuEntries" (userLoggedOut)="logout()" *ngIf="authService.isUserAuthenticated()"></app-user-menu>
    </div>

  </mat-toolbar-row>
  <mat-toolbar-row  *ngIf="authService.isUserAuthenticated()">
    <div class="mobile-nav-entries">
      <a mat-stroked-button  color="accent" [routerLink]="link.url" *ngFor="let link of primaryEntries">{{link.displayText}}</a>
    </div>

  </mat-toolbar-row>

</mat-toolbar>

<div class="mobile-select mobile-menu" *ngIf="authService.isUserAuthenticated()">
  <global-population-select [auth]="authService.isUserAuthenticated()" (populationChanged)="switchWorkspace($event)"></global-population-select>
</div>
