import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {PopulationPrey} from "../../../../../models/dto/population/populationPrey";
import {PopulationService} from "../../../../../services/population/population.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {MatPaginator} from "@angular/material/paginator";
import {MatTableDataSource} from "@angular/material/table";
import {MatDrawer} from "@angular/material/sidenav";

@Component({
  selector: 'app-population-prey',
  templateUrl: './population-prey.component.html',
  styleUrls: ['./population-prey.component.scss']
})
export class PopulationPreyComponent implements OnInit {
  @Input() population: PopulationDto | undefined;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatDrawer) drawer!: MatDrawer;

  public dataSource: any;
  public displayedColumns = ['name', 'edit', 'delete']

  public prey: Array<PopulationPrey> | undefined;
  public fetched: boolean = false;
  public editing: Map<string, boolean> | undefined;
  adding: boolean = false;
  newPrey: PopulationPrey | undefined;
  activeModule: string | undefined;

  constructor(
    private populationService: PopulationService,
    private log: ILoggingService
  ) { }

  ngOnInit(): void {
    if (this.population && this.population.id) {
      this.initializePrey()
    }
  }

  public addPreyTarget() {
    this.newPrey = {
      displayName: '',
      populationId: this.population?.id
    }
    this.adding = true;
  }

  private initializePrey() {
    this.populationService
      .getPopulationPrey(this.population!.id!)
      .subscribe({
      next: (value: Array<PopulationPrey>) => {
        this.prey = value;
        this.dataSource = new MatTableDataSource<PopulationPrey>(this.prey);
        this.dataSource.paginator = this.paginator;
        this.paginator.length = this.prey.length;
        this.fetched = true;
        this.initMap();

      },
      error: (value: HttpErrorResponse) => {
        this.log.error(`Could not fetch prey: ${value.message}`);
        this.fetched = true;
      }
    })
  }

  private initMap() {
    this.editing = new Map<string, boolean>();
    for (let i of this.prey!) {
      this.editing.set(i.id!, false);
    }
  }

  createTarget() {
    this.populationService
      .addPopulationPrey(this.population!.id!, this.newPrey!)
      .subscribe({
        next: (value: PopulationPrey) => {
          this.log.info(`${value.displayName} added`);
          this.adding = false;
          this.drawer.close();
          this.initializePrey();
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not create prey: ${value.message}`);
          this.adding = false;
          this.drawer.close();
          this.initializePrey();
        }
      })
  }

  cancelAdding() {
    this.adding = false;
  }

  activateAdding(id: string) {
    for (let i of this.prey!) {
      this.editing!.set(i.id!, false);
    }
    this.editing!.set(id, true);
  }

  updateTarget(p: PopulationPrey) {
    this.populationService
      .updatePopulationPrey(this.population!.id!, p)
      .subscribe({
        next: (value: PopulationPrey) => {
          this.log.info(`${value.displayName} updated`);
          this.initializePrey();
          this.drawer.close();
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not update prey: ${value.message}`);
          this.initializePrey();
          this.drawer.close();
        }
      })
  }

  deleteTarget(p: PopulationPrey) {
    this.populationService
      .deletePopulationPrey(this.population!.id!, p.id!)
      .subscribe({
        next: (value: PopulationPrey) => {
          this.log.info(`${p.displayName} deleted`);
          this.editing!.set(p.id!, false);
          this.initializePrey();
        },
        error: (value: HttpErrorResponse) => {
          this.log.error(`Could not delete prey: ${value.message}`);
          this.editing!.set(p.id!, false);
          this.initializePrey();
        }
      })
  }

  startAdd() {
    this.activeModule = 'addingPrey';
    this.addPreyTarget();
    this.drawer.open();


  }

  startUpdate(element: PopulationPrey) {
    this.activeModule = 'updatingPrey';
    this.newPrey = element;
    this.drawer.open();
  }
}
