import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {ContributorDto} from "../../../../../models/dto/population/contributorDto";
import {PopulationService} from "../../../../../services/population/population.service";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {Observable} from "rxjs";
import {MatDrawer} from "@angular/material/sidenav";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-population-contributors',
  templateUrl: './population-contributors.component.html',
  styleUrls: ['./population-contributors.component.scss']
})
export class PopulationContributorsComponent implements OnInit, OnChanges {
  @Input() population: PopulationDto | undefined;
  @ViewChild(MatDrawer) drawer!: MatDrawer;
  addingContributor = false;
  contributor: ContributorDto | undefined;
  contributorsObs: Observable<Array<ContributorDto>> | undefined;
  editingContributorId: string | undefined;
  displayedColumns: Iterable<string> = ['logo', 'name', 'group', 'role', 'location', 'contact', 'link', 'delete']
  dataSource: any;
  activeElement?: ContributorDto;

  constructor(
    private populationService: PopulationService,
    private log: ILoggingService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
  }

  ngOnInit(): void {
    if (this.population && this.population.id) {
      // this.contributorsObs = this.populationService.getContributors(this.population.id)
      this.populationService.getContributors(this.population.id).subscribe(res=> {
        this.dataSource = res;
      })
    }
  }

  addContributor() {
    this.addingContributor = true;
    this.contributor = {
      populationId: this.population?.id,
      new: true
    }
    this.editingContributorId = undefined;
  }

  editContributor(id: string) {
    this.addingContributor = true;
    this.contributor = undefined;
    this.editingContributorId = id;

  }

  sortContributors(contributors: Array<ContributorDto>) {
    return contributors.sort((a, b) => b.order! - a.order!)
  }

  cancel() {
    this.drawer.close();
  }

  deleteContributor(element: ContributorDto) {

  }

  startEdit(element: ContributorDto) {
    this.activeElement = element;
    this.activeElement.new = false;
    this.drawer.open();

  }

  addIcon(e: Event) {
    // @ts-ignore
    this.toBase64(e.target.files[0]).then( res => {
      this.activeElement!.icon = res as string;
    })
  }

  addAlternateIcon(e: Event) {
    // @ts-ignore
    this.toBase64(e.target.files[0]).then( res => {
      this.activeElement!.footerIcon = res as string;
    })
  }

  toBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }


  saveContributor(activeElement: ContributorDto | undefined) {
    if (!this.population || !this.population.id || !activeElement) return;
    if (!activeElement.new) {
      this.populationService.updateContributor(this.population.id, activeElement)
        .subscribe({
          next: (value: ContributorDto) => {
            this.log.info(`Updated ${value.name}`);


          },
          error: (value: HttpErrorResponse) => {
            this.log.error(`Could not update ${this.contributor?.name} : ${value.error}`);
          }
        })
    } else {
      this.populationService.addContributor(this.population.id, activeElement)
        .subscribe({
          next: (value: ContributorDto) => {
            this.log.info(`Added ${value.name}`);

          },
          error: (value: HttpErrorResponse) => {
            this.log.error(`Could not add ${this.contributor?.name} : ${value.error}`);
          }
        })
    }
    this.drawer.close();

  }
}
