import { Component, OnInit } from '@angular/core';
import {ErrorStateMatcher} from "@angular/material/core";
import {AbstractControl, UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../services/user/authentication.service";
import {UserForRegistrationDto} from "../../../models/dto/user/userForRegistrationDto";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {
  PasswordConfirmationValidatorService
} from "../../../services/user/validators/password-confirmation-validator.service";
import {UserForAuthenticationDto} from "../../../models/dto/user/userForAuthenticationDto";
import {AuthenticationResponseDto} from "../../../models/dto/response/user/authenticationResponseDto";
import {PopulationService} from "../../../services/population/population.service";
import {PopulationDto} from "../../../models/dto/population/populationDto";
import {UserContentService} from "../../../services/user/content/user-content.service";

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private returnUrl: string = "";
  public loading = false;
  registerForm!: UntypedFormGroup;
  loginForm!: UntypedFormGroup;
  registrationErrors: any = [];
  showLoginError: boolean = false;
  loginErrorMessage: string = "";
  population: PopulationDto | undefined;

  loginActive = true;

  emailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);

  matcher = new MyErrorStateMatcher();

  constructor(public authService: AuthenticationService,
              private router: Router,
              public passwordConfirmationValidator: PasswordConfirmationValidatorService,
              private route: ActivatedRoute,
              private client: HttpClient,
              private populationService: PopulationService,
              private userContentService: UserContentService
              ) {}

  ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl("", [Validators.required]),
      password: new UntypedFormControl("", [Validators.required])
    })
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.registerForm = new UntypedFormGroup({
      firstName: new UntypedFormControl(''),
      lastName: new UntypedFormControl(''),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required]),
      confirm: new UntypedFormControl('')
    });
    this.registerForm.get("confirm")?.setValidators([
      Validators.required,
      this.passwordConfirmationValidator
        .validateConfirmPassword(<AbstractControl>this.registerForm.get('password'))
    ]);
  }

  validateLoginControl = (controlName: string) => {
    return this.loginForm.get(controlName)?.invalid && this.loginForm.get(controlName)?.touched
  }
  loginHasError = (controlName: string, errorName: string) => {
    return this.loginForm.get(controlName)?.hasError(errorName)
  }


  // public registerUser = (registerFormValue: any) => {
  //   const formValues = { ...registerFormValue };
  //   const user: UserForRegistrationDto = {
  //     firstName: formValues.firstName,
  //     lastName: formValues.lastName,
  //     email: formValues.email,
  //     password: formValues.password,
  //     confirmPassword: formValues.confirm
  //   };
  //   this.loading = true;
  //   this.authService.registerUser(user)
  //     .subscribe({
  //       next: (_) => {
  //         const userForAuth: UserForAuthenticationDto = {
  //           email: formValues.email,
  //           password: formValues.password
  //         }
  //         this.authService.loginUser(userForAuth).subscribe({
  //           next: (res:AuthenticationResponseDto) => {
  //             localStorage.setItem("token", res.token);
  //             this.authService.sendAuthenticationStateChangeNotification(res.isAuthSuccessful);
  //             this.populationService.population.subscribe(res => this.population = res);
  //             this.userContentService.updateUser();
  //             this.router
  //               .navigate(['/profile'])
  //               .then();
  //           },
  //           error: (err: HttpErrorResponse) => {
  //             this.loginErrorMessage = err.message;
  //             this.showLoginError = true;
  //           }})
  //       },
  //       error: (err: HttpErrorResponse) => {
  //         for (let e of err.error.errors) {
  //           if (e.indexOf("Username") === -1) {
  //             this.registrationErrors.push(e)
  //           }
  //         }
  //       }
  //     })
  // }

  public loginUser = (loginFormValue: any) => {
    this.showLoginError = false;
    const login = {... loginFormValue };
    const userForAuth: UserForAuthenticationDto = {
      email: login.email,
      password: login.password
    }
    this.loading = true;
    this.authService.loginUser(userForAuth)
      .subscribe({
        next: (res:AuthenticationResponseDto) => {
          localStorage.setItem("token", res.token);
          localStorage.setItem("version","9");
          localStorage.setItem("refreshToken", res.refreshToken);
          this.authService.sendAuthenticationStateChangeNotification(res.isAuthSuccessful);
          this.authService.setInterval(res.token)
          this.loading = false;
          this.populationService.population.subscribe(res => this.population = res);
          this.userContentService.updateUser();
          this.router
            .navigate([this.returnUrl])
            .then();

        },
        error: (err: HttpErrorResponse) => {
          if (err.status == 401) {
            this.loginErrorMessage = "Login failed. Email or password incorrect"
          } else {
            this.loginErrorMessage = err.message;
          }

          this.showLoginError = true;
          this.loading = false;
        }})
  }

}
