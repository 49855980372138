<div class="content-container">
  <div class="content">
    <mat-action-list>
      <a [routerLink]="'/administration/invitations'" mat-list-item>Platform Invitation Management</a>
      <a [routerLink]="'/administration/api'" mat-list-item>API Management</a>
      <a [routerLink]="'/user-management'" mat-list-item>Users</a>
      <a [routerLink]="'/administration/population-management'" mat-list-item>Population Applications</a>
    </mat-action-list>


  </div>
</div>

<!--<ng-template #loading>-->
<!--  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="25" [color]="'accent'"></mat-progress-spinner>-->
<!--</ng-template>-->
