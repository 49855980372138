import {api} from "./api";
export const environment = {
  production: false,
  electron: false,
  hostUrl: "",
  appName: "finwave",
  slogan: "Dorsal Fin Identification",
  headerImg: "assets/headerImages/IMG_1062_CROPPED.JPG",
  aboutHeaderImg: "assets/headerImages/JTowers-08907.jpg",
  darkLogo: "assets/logos/finwave_darkC.png",
  lightLogo: "assets/logos/finwave_lightC.png",
  developers: [
    {
      name: "Friedrich-Alexander Universität",
      link: "https://fau.de",
      logos: {
        dark: "assets/logos/fau_logo_white-no-sub.png",
        light: "assets/logos/fau-logo-blue.png"
      },
      displayName: true
    },
    {
      name: "Bay Cetology",
      link: "https://baycetology.org",
      logos: {
        dark: "assets/logos/BayCetology_RGB.png",
        light: "assets/logos/bay-cetology-logo.png",
      },
      displayName: false
    }
  ],
  server: {
    pingInterval: 5,
    baseUrl: "",
    status: "",
    onlineIndicator:"",
    api: api,
    image: {
      get: "",
      upload: ""
    },
    debugMode: true,
    imageServer: ""
  },
  maps: {
    googleApiKey: "AIzaSyAg9-FoPj6Mi1s7mF0kGtcgNO6Ql9dyTbc"
  },
  design: {
    mobileMax: 767,
    tabletMax: 1200
  }
};
