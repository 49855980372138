<mat-drawer-container class="editing-sidebar">
  <mat-drawer mode="over" #drawer position="end" class="editing-sidebar-drawer-content">
    <ng-container *ngIf="activeModule === 'addingBehavior'">
      <ng-container *ngTemplateOutlet="behaviorAddContent"></ng-container>
    </ng-container>

    <ng-container *ngIf="activeModule === 'updatingBehavior'">
      <ng-container *ngTemplateOutlet="behaviorUpdateContent"></ng-container>
    </ng-container>
  </mat-drawer>
  <mat-drawer-content>
    <div class="content-container">
      <div class="content-header">
        <button mat-button (click)="addBehavior()"><mat-icon>add</mat-icon>Create</button>
      </div>
      <mat-divider></mat-divider>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">

          <!-- Position Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Description</th>
            <td mat-cell *matCellDef="let element"> {{element.description}} </td>
          </ng-container>
          <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>Update</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="startEdit(element)"><mat-icon>edit</mat-icon></button>
            </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="deleteBehavior(element.id)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]"
                       showFirstLastButtons
                       aria-label="Select page of population behavior">
        </mat-paginator>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>




<ng-template #behaviorAddContent>
  <div class="content" *ngIf="newBehavior !== undefined">
    <mat-form-field color="accent">
      <mat-label>Behavior Description</mat-label>
      <input matInput [(ngModel)]="newBehavior.description" />
    </mat-form-field>

    <div class="button-row">
      <button mat-button (click)="drawer.close()">Cancel</button>
      <button mat-button (click)="createBehavior()">Save</button>
    </div>

  </div>
</ng-template>

<ng-template #behaviorUpdateContent>
  <div class="content" *ngIf="editingBehavior !== undefined">
    <mat-form-field color="accent">
      <mat-label>Behavior Description</mat-label>
      <input matInput [(ngModel)]="editingBehavior.description" />
    </mat-form-field>

    <div class="button-row">
      <button mat-button (click)="drawer.close()">Cancel</button>
      <button mat-button (click)="updateBehavior()">Save</button>
    </div>

  </div>
</ng-template>


<!--<mat-action-list *ngIf="behaviors">-->
<!--  <mat-list-item *ngFor="let behavior of behaviors" class="auto-height">-->
<!--    <div *ngIf="editingId !== behavior.id" (click)="startEdit(behavior)">-->
<!--      {{behavior.description}}-->
<!--    </div>-->
<!--    <div *ngIf="editingId === behavior.id" class="behavior-edit">-->
<!--      <mat-form-field class="full-width" color="accent" *ngIf="editingBehavior !== undefined">-->
<!--        <mat-label>Behavior Description</mat-label>-->
<!--        <input matInput [(ngModel)]="editingBehavior.description"/>-->
<!--      </mat-form-field>-->
<!--      <div class="button-row">-->
<!--        <button mat-button (click)="updateBehavior()">Save Behavior</button>-->
<!--        <button mat-button (click)="deleteBehavior(behavior.id)">Delete Behavior</button>-->
<!--        <button mat-button (click)="cancelEdit()">Cancel</button>-->
<!--      </div>-->

<!--    </div>-->
<!--  </mat-list-item>-->
<!--</mat-action-list>-->
<!--<div class="adding" *ngIf="adding">-->
<!--  <mat-form-field *ngIf="newBehavior !== undefined" class="full-width" color="accent">-->
<!--    <mat-label>Behavior Description</mat-label>-->
<!--    <input matInput [(ngModel)]="newBehavior.description"/>-->
<!--  </mat-form-field>-->
<!--  <button mat-button (click)="createBehavior()">Save Behavior</button>-->
<!--  <button mat-button (click)="adding = false">Cancel</button>-->
<!--</div>-->
<!--<button mat-button (click)="addBehavior()" *ngIf="!adding && !editing">Add Behavior</button>-->
