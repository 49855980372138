import {Component, Input, OnInit} from '@angular/core';
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {Observable} from "rxjs";

@Component({
  selector: 'app-population-administration',
  templateUrl: './population-administration.component.html',
  styleUrls: ['./population-administration.component.scss']
})
export class PopulationAdministrationComponent implements OnInit {

  @Input() populations: Observable<Array<PopulationDto>> | undefined;

  displayedColumns: string[] = ['displayName', 'speciesName'];
  dataSource: Array<PopulationDto> = [];

  constructor() { }

  ngOnInit(): void {
    if (this.populations) {
      this.populations.subscribe(res => {
        this.dataSource = res;
      })
    }
  }

  getLink(population: PopulationDto) {
    return `/administration/populations/${population.id}`;
  }

  joinPopulation() {

  }
}
