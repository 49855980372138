import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {UserForRegistrationDto} from "../../models/dto/user/userForRegistrationDto";
import {RegistrationResponseDto} from "../../models/dto/response/user/registrationResponseDto";
import {environment} from "../../environments/environment";
import {UserForAuthenticationDto} from "../../models/dto/user/userForAuthenticationDto";
import {AuthenticationResponseDto} from "../../models/dto/response/user/authenticationResponseDto";
import {BehaviorSubject, Observable, Subject, timer} from "rxjs";
import {JwtHelperService} from "@auth0/angular-jwt";
import {UserProfileDto} from "../../models/dto/user/userProfileDto";
import {UserForUpdateDto} from "../../models/dto/user/userForUpdateDto";
import {AuthenticationUpdateResponseDto} from "../../models/dto/response/user/authenticationUpdateResponseDto";
import {ForgotPasswordDto} from "../../models/dto/user/forgotPasswordDto";
import {ResetPasswordDto} from "../../models/dto/user/resetPasswordDto";
import {PopulationDto} from "../../models/dto/population/populationDto";


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private authenticationChangeSubject = new Subject<boolean>();
  public authenticationChanged = this.authenticationChangeSubject.asObservable();
  private _user: BehaviorSubject<UserProfileDto> = new BehaviorSubject({});
  public readonly user: Observable<UserProfileDto> = this._user.asObservable();

  private readonly adminRole = "Administrator";
  private readonly expertRole = "Expert";
  private readonly professionalRole = "Professional";
  private logoutTimer: any;


  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService) {
  }

  public registerUser = (body: UserForRegistrationDto) => {
    const url = `${environment.server.baseUrl}/api/authentication/registration`;
    return this.http.post<RegistrationResponseDto> (url, body);
  }

  public createUser = (body: UserProfileDto) => {
    const url = `${environment.server.baseUrl}/api/authentication/create`;
    return this.http.post<RegistrationResponseDto> (url, body);
  }

  public isUserAdmin = (): boolean => {
    const token = localStorage.getItem("token");
    if (token == null) {return false;}
    const decodedToken = this.jwtHelper.decodeToken(token);
    const role = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    return role === this.adminRole;

  }

  public isUserExpert = (): boolean => {
    const token = localStorage.getItem("token");
    if (token == null) {return false;}
    const decodedToken = this.jwtHelper.decodeToken(token);
    const role = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    return role === this.expertRole;
  }

  public isUserProfessional = (): boolean => {
    const token = localStorage.getItem("token");
    if (token == null) {return false;}
    const decodedToken = this.jwtHelper.decodeToken(token);
    const role = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    return role === this.professionalRole;
  }


  public loginUser = (body: UserForAuthenticationDto) => {
    const url = `${environment.server.baseUrl}${environment.server.api.authentication.login}`;
    return this.http.post<AuthenticationResponseDto>(url, body);
  }

  public checkHeartbeat = () => {
    const url = `${environment.server.baseUrl}${environment.server.api.heartbeat.root}`;
    return this.http.get(url);
  }

  public updateUser = (body: UserForUpdateDto) => {
    const url = `${environment.server.baseUrl}${environment.server.api.authentication.update}`;
    return this.http.put<AuthenticationUpdateResponseDto>(url, body);
  }

  public logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("version");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("WHALEEGOODTIME");
    this.sendAuthenticationStateChangeNotification(false);
  }

  public sendAuthenticationStateChangeNotification = (isAuthenticated: boolean) => {
    this.authenticationChangeSubject.next(isAuthenticated);
  }

  public isUserAuthenticated = (): boolean => {
    const token = (localStorage.getItem("version")=="9") ? localStorage.getItem("token"):null;
    
    return token !== null && !this.jwtHelper.isTokenExpired(token);
  }

  public getClaims = () => {
    const url = `${environment.server.baseUrl}/api/authentication/claims`
    return this.http.get(url);
  }

  public forgotPassword = (body: ForgotPasswordDto) => {
    const url = `${environment.server.baseUrl}${environment.server.api.authentication.forgotPassword}`;
    return this.http.post(url, body);
  }

  public resetPassword = (body: ResetPasswordDto) => {
    const url = `${environment.server.baseUrl}${environment.server.api.authentication.resetPassword}`;
    return this.http.post(url, body);
  }

  public userManualResetPassword = (body: ResetPasswordDto) => {
    const url = `${environment.server.baseUrl}${environment.server.api.authentication.userManualPasswordReset}`;
    return this.http.post(url, body);
  }

  public adminUserPasswordChange = (body: ResetPasswordDto) => {
    const url = `${environment.server.baseUrl}${environment.server.api.authentication.adminUserPasswordChange}`;
    return this.http.post(url, body);
  }

  public refreshToken(): Observable<AuthenticationResponseDto> {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const url = `${environment.server.baseUrl}/api/token/refresh`;
    return this.http.post<AuthenticationResponseDto>(url, {accessToken: token, refreshToken: refreshToken});
  }

  setInterval(token: string) {
    const expirationDate = this.jwtHelper.getTokenExpirationDate(token);
    let difference = 0;
    if (!expirationDate) return;
    difference = expirationDate.getTime() - Date.now();
    this.logoutTimer = timer(difference);
    this.logoutTimer.subscribe( (res: any) => {
      this.refreshToken().subscribe({
        next: (value: AuthenticationResponseDto) => {
          localStorage.setItem("token", value.token);
          localStorage.setItem("version", "9");
          localStorage.setItem("refreshToken", value.refreshToken);
          this.authenticationChangeSubject.next(this.isUserAuthenticated());
        },
        error: (err: HttpErrorResponse) => {
          this.authenticationChangeSubject.next(this.isUserAuthenticated());
        }
      })

    })
  }
}
