import {Component, OnInit, ViewChild} from '@angular/core';
import {WorkspaceService} from "../../../../../../../services/workspace/workspace.service";
import {WorkspaceDto} from "../../../../../../../models/dto/workspace/workspaceDto";
import {PopulationDto} from "../../../../../../../models/dto/population/populationDto";
import {DataExportService} from "../../../../../../../services/export/data-export.service";
import {ImageDataExportRequestDto} from "../../../../../../../models/dto/export/imageDataExportRequestDto";
import {ILoggingService} from "../../../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDrawer} from "@angular/material/sidenav";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'app-population-data-access',
  templateUrl: './population-data-access.component.html',
  styleUrls: ['./population-data-access.component.scss']
})
export class PopulationDataAccessComponent implements OnInit {
  public creatingExport: boolean = false;
  public submitting: boolean = false;
  @ViewChild(MatDrawer) drawer!: MatDrawer;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public dataSource: any;
  public displayedColumns = ['name', 'from', 'to']

  private workspace?: WorkspaceDto

  public population?: PopulationDto

  public request?: ImageDataExportRequestDto
  activeModule?: string;
  activeElement?: ImageDataExportRequestDto;
  constructor(
    private workspaceService: WorkspaceService,
    private exportService: DataExportService,
    private log: ILoggingService
  ) {
    this.workspaceService.workspace.subscribe(res => {
      this.workspace = res;
      this.population = res.settings?.population;

    })
  }

  startExportRequest() {
    this.activeModule = 'newRequest';
    this.activeElement = {
      from: new Date(),
      to: new Date(),
      populationId: this.population!.id!,
      name: "TrainingData",
      encounterStructure: false,
      includeAttribution: false
    }
    this.drawer.open();
  }


  submit() {
    if (!this.activeElement) return;
    this.submitting = true;
    this.exportService.sendTrainingImageDataExportRequest(this.activeElement).subscribe({
      next: (value: any) => {
        this.log.info("Request submitted. You will receive an email as soon as your download is finished.");
        this.submitting = false;
        this.creatingExport = false;
      }, error: (value: HttpErrorResponse) => {
        this.log.info(`Could not submit request: ${value}`);
        this.submitting = false;
        this.creatingExport = false;
      }
    })
    this.drawer.close();
  }

  cancel() {
    this.creatingExport = false;
  }

  ngOnInit(): void {
    this.exportService.getImageDataExportRequests(this.population?.id!).subscribe( res => {
      this.dataSource = res;
      this.dataSource.paginator = this.paginator;
      this.paginator.length = res.length;
    })
  }
}
