<mat-sidenav-container>
  <mat-sidenav [mode]="getMode()" [opened]="!isMobile || (isMobile && sidebarOpen)" [position]="'end'" (closed)="sidebarOpen=false" class="encounter-select-drawer" [style.width.px]="310" [style.min-height.vh]="91">
    <div class="individual-select-drawer-content">
      <app-individual-search-drawer [status]="selectedLifeStatus" [dto]="request" [animals]="animals" (searchComplete)="updateResults($event)" (searchReset)="updateResults($event)" (searchSubmitted)="submitSearch($event)"></app-individual-search-drawer>
    </div>
  </mat-sidenav>
  <mat-sidenav-content [style.min-height.vh]="95">
    <div class="content-container" *ngIf="animals !== undefined">
      <div class="content-header">
        <div class="sidebar-toggle" *ngIf="isMobile">
          <button mat-raised-button color="accent" (click)="sidebarOpen = !sidebarOpen">Search</button>
        </div>
        <div class="status-selection">
          <mat-button-toggle-group name="lifeStatus" aria-label="Life Status" [(ngModel)]="selectedLifeStatus" (ngModelChange)="updateStatus()">
            <mat-button-toggle *ngFor="let v of statuses" [value]="v">{{v.displayName}}</mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>
      <div class="content">
        <div class="catalog-content content" *ngIf="!searchResults">
          <a class="profile-overview" *ngFor="let animal of animals" [href]="getIndividualLink(animal)">
            <div class="profile-image gradient-box"></div>
<!--            <app-gallery-preview-loading-image class="gradient-box"></app-gallery-preview-loading-image>-->
            <div class="profile-image">
              <img *ngIf="animal.image !== undefined" [src]="imageService.prefixImg(animal.image!)" loading="lazy" [alt]="animal.identifier"/>
            </div>
            <div class="profile-overlay">
              <div class="identifiers">
                <h2 class="shade">{{animal.identifier}}
                </h2>
<!--                <h3 *ngIf="animal.nickname" class="shade">{{animal.nickname}}</h3>-->
              </div>

            </div>
          </a>
        </div>
        <div class="search-content content" *ngIf="searchResults">
          <div class="profile-overview" *ngFor="let animal of searchResults" (click)="viewImage(animal)">
            <div class="profile-image">
              <img *ngIf="animal.image !== undefined" [src]="imageService.prefixImg(animal.image!)" loading="lazy" [alt]="animal.identifier"/>
            </div>
            <div class="profile-overlay">
              <div class="not-hover-overlay identifiers">
                <h2 class="shade">{{animal.identifier}}</h2>
              </div>
              <div class="hover-overlay shade" >
                <div class="identifiers">
                  <h2>{{animal.identifier}}</h2>
                  <div class="supplementary">
                    <h4>Date: {{getDate(animal.date)}}</h4>
                    <h4 *ngIf="animal.location">Location: {{animal.location}}</h4>
                    <h4 *ngIf="animal.photographer">Photographer: {{animal.photographer}}</h4>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>

    </div>
  </mat-sidenav-content>
</mat-sidenav-container>


<div class="content-container empty-content-container" *ngIf="animals !== undefined && animals!.length == 0 && fetchFinished && fetchStarted && !searchStarted">
  <div class="empty-content">
    <h1>Nothing to View</h1>
    <div class="button-row">
      <a mat-raised-button color="primary" [routerLink]="'/submit'">Submit New Data</a> <span><h3>Or</h3></span> <a mat-raised-button color="primary" [routerLink]="'/encounters'">Look through Encounters</a>
    </div>
  </div>
</div>
<app-loading-overlay [criteria]="!animals" [title]="'Fetching Individuals...'"></app-loading-overlay>
<ng-template #loading>
  <div class="loading-block">
    <h2 *ngIf="animals !== null && animals !== undefined && animals!.length === 0"> No Individuals Found</h2>

  </div>
</ng-template>

<app-loading-overlay [criteria]="searchStarted" [title]="'Searching...'"></app-loading-overlay>
