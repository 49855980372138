<div class="content-container" *ngIf="population">
  <div class="tab-button-row">
    <div class="tab-buttons">
      <button mat-button color="accent" (click)="updateBanner()"><mat-icon>edit</mat-icon>Update Banner</button>
    </div>

    <mat-divider></mat-divider>
  </div>


  <div class="banner-container">
    <div class="banner" *ngIf="population.banner">
      <div class="about-banner">
        <div class="banner-img">
          <img [src]="imageService.maybePrefix(population.banner)" loading="lazy" [alt]="population.displayName"/>
        </div>
      </div>

    </div>
  </div>
  <div class="banner" *ngIf="!population.banner">
    <h3>No Banner Selected!</h3>
  </div>
</div>
