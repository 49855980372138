import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {ImageAnnotationComponent} from "./global/image-annotation/image-annotation.component";
import {LoginComponent} from "./user/login/login.component";
import {UserHomeComponent} from "./user/user-home/user-home.component";
import {AuthGuard} from "../guards/auth.guard";
import {SubmitDataComponent} from "./fin-print/submit-data/submit-data.component";

import {ManagementComponent} from "./user/management/management.component";

import {ForbiddenComponent} from "./global/forbidden/forbidden.component";
import {DataExportComponent} from "./fin-print/data-export/data-export.component";
import {ForgotPasswordComponent} from "./user/forgot-password/forgot-password.component";
import {ResetPasswordComponent} from "./user/reset-password/reset-password.component";
import {AdministrationComponent} from "./user/administration/administration.component";
import {AboutComponent} from "./fin-print/about/about.component";
import {EncounterPageComponent} from "./fin-print/encounter/encounter-page/encounter-page.component";
import {
  SinglePopulationAdministrationComponent
} from "./user/administration/populations/population-administration/single-population-administration/single-population-administration.component";
import {
  SingleOrganizationAdministrationComponent
} from "./user/administration/organizations/organization-administration/single-organization-administration/single-organization-administration.component";
import {
  PopulationCreationManagementComponent
} from "./user/administration/platform-administration/population-creation-management/population-creation-management.component";
import { OrganizationProfileComponent } from './user/organization/organization-profile/organization-profile.component';
import {AnimalProfilesComponent} from "./fin-print/animal-profile/animal-profiles.component";
import {ProfileComponent} from "./fin-print/animal-profile/profile/profile.component";
import {EncounterGridComponent} from "./fin-print/encounter/encounter-grid/encounter-grid.component";
import {InvitationListComponent} from "./global/invitations/invitation-list/invitation-list.component";
import {AdminGuard} from "../guards/admin.guard";
import {InvitationRedeemComponent} from "./user/invitation/invitation-redeem/invitation-redeem.component";
import {
  ApiKeyAdministrationComponent
} from "./user/administration/platform-administration/api-key-administration/api-key-administration.component";
import {
  PopulationSettingsPageComponent
} from "./user/administration/populations/population-administration/single-population-administration/population-settings-page/population-settings-page.component";
import {
  PopulationMembersComponentComponent
} from "./user/administration/populations/population-members-component/population-members-component.component";
import {
  PopulationMembersPageComponent
} from "./user/administration/populations/population-administration/single-population-administration/population-members-page/population-members-page.component";
import {
  PopulationContributorsComponent
} from "./user/administration/populations/population-contributors/population-contributors.component";
import {PopulationAdminGuard} from "../guards/populationAdmin.guard";
import {
  PopulationContributorsPageComponent
} from "./user/administration/populations/population-administration/single-population-administration/population-contributors-page/population-contributors-page.component";
import { OrganizationProfileListComponent } from './user/organization/organization-profile-list/organization-profile-list.component';
import {
  PopulationIndividualsComponent
} from "./user/administration/population-individuals/population-individuals.component";
import { DataExportDownloadComponent } from './fin-print/data-export/data-export-download/data-export-download.component';
import { DataExportReviewComponent } from './fin-print/data-export/data-export-review/data-export-review.component';
import {
  PopulationMembershipRequestsComponent
} from "./user/administration/population/population-membership-requests/population-membership-requests.component";
import {
  PopulationDataAccessComponent
} from "./user/administration/populations/population-administration/single-population-administration/population-data-access/population-data-access.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "image-annotation", component: ImageAnnotationComponent, canActivate: [AuthGuard] },
  // { path: "encounters", component: BrowseComponent},
  { path: "encounters", component: EncounterGridComponent, canActivate: [AuthGuard] },
  { path: "encounters/:encounterId", component: EncounterPageComponent, canActivate: [AuthGuard] },
  //{ path: "encounters/:encounterId", component: EncounterPageComponent, canActivate: [AuthGuard] },
  { path: ":populationId/encounters/:encounterId", component: EncounterPageComponent, canActivate: [AuthGuard]},
  { path: "login", component: LoginComponent },
  { path: "profile", component: UserHomeComponent, canActivate: [AuthGuard]},
  { path: "users/profile/:id", component: UserHomeComponent, canActivate: [AuthGuard]},
  { path: "submit", component: SubmitDataComponent, canActivate: [AuthGuard]},
  { path: "forbidden", component: ForbiddenComponent},
  { path: "individuals", component: AnimalProfilesComponent, canActivate: [AuthGuard]},
  { path: "individuals/:id", component: ProfileComponent, canActivate: [AuthGuard]},
  { path: "users/:id", component: UserHomeComponent, canActivate: [AuthGuard]},
  { path: "export", component: DataExportComponent, canActivate: [AuthGuard] },
  { path: "export/download/:id", component: DataExportDownloadComponent, canActivate: [AuthGuard] },
  { path: "export/review/:id", component: DataExportReviewComponent, canActivate: [AuthGuard] },
  { path: "user-management", component: ManagementComponent, canActivate: [AuthGuard]},
  { path: "population-management", component: PopulationCreationManagementComponent, canActivate: [AuthGuard]},
  { path: "administration", component: AdministrationComponent, canActivate: [AuthGuard]},
  { path: "administration/organizations/:id", component: SingleOrganizationAdministrationComponent, canActivate: [AuthGuard]},
  { path: "administration/populations/:id", component: SinglePopulationAdministrationComponent, canActivate: [PopulationAdminGuard]},
  { path: "administration", component: AdministrationComponent, canActivate: [AuthGuard]},
  { path: "organizations", component: OrganizationProfileListComponent, canActivate: [AuthGuard] },
  { path: "forgotpassword", component: ForgotPasswordComponent },
  { path: 'resetpassword', component: ResetPasswordComponent },
  { path: 'organization/profile/:organizationid', component: OrganizationProfileComponent },
  { path: 'settings', component: AdministrationComponent, canActivate: [AuthGuard]},
  { path: 'administration/invitations', component: InvitationListComponent, canActivate: [AdminGuard] },
  { path: 'administration/api', component: ApiKeyAdministrationComponent, canActivate: [AdminGuard] },
  { path: "administration/organizations/profile/:organizationid", component: OrganizationProfileComponent },
  { path: "invitation/redeem/:key", component: InvitationRedeemComponent},
  { path: "about", component: AboutComponent, canActivate: [AuthGuard]},
  { path: "administration/populations/:id/settings", component: PopulationSettingsPageComponent, canActivate: [PopulationAdminGuard]},
  { path: "administration/populations/:id/members", component: PopulationMembersPageComponent, canActivate: [PopulationAdminGuard]},
  { path: "administration/populations/:id/contributors", component: PopulationContributorsPageComponent, canActivate: [PopulationAdminGuard]},
  { path: "administration/populations/:id/individuals", component: PopulationIndividualsComponent, canActivate: [PopulationAdminGuard]},
  { path: "administration/populations/:id/requests", component: PopulationMembershipRequestsComponent, canActivate: [PopulationAdminGuard]},
  { path: "administration/populations/:id/data", component: PopulationDataAccessComponent, canActivate: [PopulationAdminGuard]},

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
