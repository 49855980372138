<div class="container" *ngIf="encounter !== undefined">
  <div class="encounter-title">
    <div class="encounter-title-main" *ngIf="title === undefined">
      <h2>{{formatTime(encounter.dateTime)}}</h2>
      <h2 *ngIf="encounter.location !== undefined">{{encounter.location.name}}</h2>
      <h2 *ngIf="user">{{user.firstName}} {{user.lastName}}</h2>
      <div class="info-tooltip-container" *ngIf="tooltipContent && tooltip">
        <mat-icon [matTooltip]="tooltipContent"
                  matTooltipClass="encounter-tooltip-info"
                  [matTooltipPosition]="'after'"
                  color="accent">information</mat-icon>
      </div>
    </div>
    <div class="encounter-title" *ngIf="title !== undefined">
      <div class="encounter-title-main">
        <h2>{{title}}</h2>
      </div>
    </div>

    <div class="encounter-edit">
      <app-encounter-edit-component [encounter]="encounter"
                                    [encounterUser]="user"
                                    [population]="population"
                                    [populationSettings]="populationSettings"
                                    (userChanged)="user = $event"
                                    (itemsAdded)="addImages($event)"
                                    (markingForDeletion)="markForDeletion = $event"
                                    (markingForSideLabel)="markForSideLabel = $event"
                                    (encounterDeleted)="encounterDeleted($event)"
                                    (reloadRequested)="reload()"
                                    [items]="items"></app-encounter-edit-component>
    </div>
<!--    <div class="encounter-intuition">-->
<!--    </div>-->

  </div>
  <div class="encounter-gallery" *ngIf="loadImages">
        <h4 *ngIf="markForDeletion">Enabling image deletion...</h4>
        <global-gallery *ngIf="items && suggestions; else loading"
                        [images]="items"
                        [showHeader]="false"
                        [suggestions]="suggestions"
                        [markForDeletion]="markForDeletion"
                        [markForSideLabel]="markForSideLabel"
                        [selectedImageId]="selectedImageId"
                        [encounter]="encounter"
                        [user]="workspace?.settings?.user"
                        [tab]="getDisplayTab()"
                        [animalObs]="animalObs"
                        (annotationUpdated)="fetchSuggestions()"
                        (annotationConfirmed)="fetchSuggestions()"
                        (annotationRemoved)="fetchSuggestions()"
                        (annotationCreated)="fetchSuggestions()"
        ></global-gallery>
  </div>
  <button mat-stroked-button (click)="loadImages = true" *ngIf="!loadImages">Load Images</button>
<!--  <div class="suggestion-temp" *ngIf="suggestionObs | async as suggestions; else loading">-->
<!--    <span *ngFor="let suggestion of suggestions">{{suggestion.identifier}}, </span>-->
<!--  </div>-->
</div>
<mat-divider></mat-divider>
<ng-template #loading>
<!--  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="35" [color]="'accent'"></mat-progress-spinner>-->
  <app-gallery-preview-loading [encounter]="encounter"  ></app-gallery-preview-loading>
</ng-template>

<div class="loading-next">
  <app-loading-overlay [criteria]="finishedLoading" [title]="'Fetching Images...'"></app-loading-overlay>
</div>

