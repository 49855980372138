import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl} from "@angular/forms";
import * as moment from "moment/moment";
import {Moment} from "moment/moment";
import {MatDatepicker} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-year-selection',
  templateUrl: './year-selection.component.html',
  styleUrls: ['./year-selection.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS
    },
  ]
})
export class YearSelectionComponent implements OnInit {
  @Input() title: string = "Year";
  @Input() startDate: Date | undefined;
  @Output() yearSelected: EventEmitter<Date> = new EventEmitter<Date>();
  date = new UntypedFormControl(moment());
  constructor() { }

  ngOnInit(): void {
    if (!this.startDate) {
      // this.startDate = new Date();
    }
    this.date.setValue(moment(this.startDate))
  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    if (!this.startDate) {
      this.startDate = new Date();
      this.date.setValue(moment(this.startDate));
    }
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    datepicker.close();
    this.yearSelected.emit(this.date.value.toDate());
  }

}
