<div class="content-container" *ngIf="population">
  <mat-tab-group color="accent" [mat-stretch-tabs]="false" [animationDuration]="0" (selectedIndexChange)="setActiveTab($event)">

    <mat-tab label="Details">
      <app-population-settings-component [population]="population" [canLoad]="true"></app-population-settings-component>
    </mat-tab>
    <mat-tab label="Description">
      <app-population-description [population]="population"></app-population-description>
    </mat-tab>

    <mat-tab label="Prey">
      <app-population-prey [population]="population"></app-population-prey>
    </mat-tab>
    <mat-tab label="Behavior">
      <app-population-behavior [population]="population"></app-population-behavior>
    </mat-tab>
    <mat-tab label="Banner">
      <app-population-banner [population]="population"></app-population-banner>
    </mat-tab>
  </mat-tab-group>

</div>
