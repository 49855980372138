<div class="organization-preview-container" *ngIf="organizationpreview" [routerLink]="getRouterLink(organizationpreview)">
  <div class="member-overlay shader">
    <div class="default" *ngIf="!organizationpreview.logo">
      <svg width="200" height="200" [data-jdenticon-value]="getJdenticon(organizationpreview)"></svg>
    </div>
    <div class="organization-preview-image" *ngIf="organizationpreview.logo">
      <img [src]="maybePrefix(organizationpreview.logo)" loading="lazy" [alt]="organizationpreview.name">
    </div>
    <div class="organization-info">
      <div class="organization-title">
        <h3><span>{{organizationpreview.name}}</span></h3>
      </div>
      <div class="organization-url">
        <h4><span>{{organizationpreview.url}}</span></h4>
      </div>
    </div>
  </div>
</div>
