import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthenticationService} from "../services/user/authentication.service";
import {AuthenticationResponseDto} from "../models/dto/response/user/authenticationResponseDto";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {JwtHelperService} from "@auth0/angular-jwt";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private authService: AuthenticationService, private router: Router, private jwtHelper: JwtHelperService, private http: HttpClient) {
  }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (this.authService.isUserAuthenticated()) {
      return true;
    }
    const token = (localStorage.getItem("version")=="9")?localStorage.getItem("token"):null;
    const isRefreshSuccess = await this.tryRefreshingTokens(token);
    if (!isRefreshSuccess) {
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    }
    return false;
  }

  private async tryRefreshingTokens(token: string | null): Promise<boolean> {
    const refreshToken: string | null = localStorage.getItem("refreshToken");
    if (!token || !refreshToken) {
      return false;
    }

    const credentials = JSON.stringify({accessToken: token, refreshToken: refreshToken});
    let isRefreshSuccess: boolean;
    const refreshRes = await new Promise<AuthenticationResponseDto>((resolve, reject) => {
      const url = `${environment.server.baseUrl}/api/token/refresh`;
      this.http.post<AuthenticationResponseDto>(url, credentials, {
        headers: new HttpHeaders({
          "Content-Type": "application/json"
        })
      }).subscribe({
        next: (res: AuthenticationResponseDto) => resolve(res),
        error: (_) => {
          reject;
          isRefreshSuccess = false;
        }
      });
    });
    localStorage.setItem("token", refreshRes.token);
    localStorage.setItem("version", "9");
    localStorage.setItem("refreshToken", refreshRes.refreshToken);
    isRefreshSuccess = true;
    return isRefreshSuccess;
  }
}
