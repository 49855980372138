<div class="content-container">
  <div class="content">
    <div class="content-header">
      <div class="">
        <h1>Settings</h1>
      </div>
    </div>
    <mat-drawer-container class="example-container">
      <mat-drawer mode="side" opened>

        <mat-action-list>
          <button mat-list-item [ngClass]="{'active': activeModule == 'Organizations'}" (click)="loadModule('Organizations')">Organizations</button>
          <button mat-list-item [ngClass]="{'active': activeModule == 'Populations'}"  (click)="loadModule('Populations')">Populations</button>
          <button mat-list-item [ngClass]="{'active': activeModule == 'Administration'}"  (click)="loadModule('Administration')">Administration</button>
        </mat-action-list>

      </mat-drawer>
      <mat-drawer-content>
        <div class="drawer-content">
          <h3>{{activeModule}}</h3>

          <div *ngIf="activeModule=='Organizations'">
            <app-organization-administration [organizations]="organizations"></app-organization-administration>
          </div>
          <div *ngIf="activeModule=='Populations'">
            <app-population-administration [populations]="populations"></app-population-administration>
          </div>
          <div *ngIf="activeModule=='Administration'">
            <app-platform-administration *ngIf="admin"></app-platform-administration>
          </div>

        </div>
      </mat-drawer-content>
    </mat-drawer-container>
  </div>
</div>



