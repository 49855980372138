<div class="population-description-container" *ngIf="population && population.description !== undefined">

  <div class="tab-button-row">
    <div class="tab-buttons">
      <button mat-button color="accent" *ngIf="!editing" (click)="editing = !editing"><mat-icon>edit</mat-icon>Update Description</button>
      <button mat-button color="accent" *ngIf="editing" (click)="update()"><mat-icon>update</mat-icon>Update Description</button>
      <button mat-button color="warn" *ngIf="editing" (click)="editing = !editing"><mat-icon>cancel</mat-icon>Cancel</button>
    </div>

    <mat-divider></mat-divider>
  </div>

  <div class="population-description" *ngIf="!editing">
    <div [innerHTML]="population.description"></div>
  </div>
  <div class="population-description-edit" *ngIf="editing">
    <ckeditor [editor]="Editor" [data]="population.description" (change)="log($event)" [tagName]="'editor'"></ckeditor>

  </div>

</div>
