import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PopulationDto} from "../../../../../../../models/dto/population/populationDto";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {PopulationService} from "../../../../../../../services/population/population.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ILoggingService} from "../../../../../../../services/logging/logging.service.interface";
import {MatTabGroup} from "@angular/material/tabs";

@Component({
  selector: 'app-population-settings-page',
  templateUrl: './population-settings-page.component.html',
  styleUrls: ['./population-settings-page.component.scss']
})
export class PopulationSettingsPageComponent implements OnInit, OnDestroy, AfterViewInit {
  public population?: PopulationDto

  private subs: Array<Subscription> = new Array<Subscription>()
  public loading = false;
  public id?: string;
  public params: any;
  public activeTab = 0;
  @ViewChild(MatTabGroup) tabs!: MatTabGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private populationService: PopulationService,
    private log: ILoggingService
  ) {
  }

  ngOnDestroy() {
    for(var sub of this.subs) {
      sub.unsubscribe();
    }
  }

  ngAfterViewInit() {


  }

  ngOnInit() {
    this.subs.push(
      this.route.params.subscribe({
        next: (value: any) => {
          if (value["id"]) {
            this.id = value["id"]
            this.loading = true;
            this.loadPopulation();
          }

        }
      })
    )

  }

  private loadPopulation() {
    if (!this.id) {
      this.loading = false;
      return;
    }
    this.subs!.push(this.populationService.getPopulationById(this.id).subscribe({
      next: (value:PopulationDto) => {
        this.population = value;
        this.loading = false;
      },
      error: (value: HttpErrorResponse) => {
        this.log.error(`Could not load population: ${value.message};`)
        this.loading = false;
      }
    }))
  }


  setActiveTab($event: number) {
    // this.params["tab"] = $event;
    this.activeTab = $event;
    // const url = this.router.url.split('?')[0]
    // this.router.navigate([url], {queryParams: this.params})
  }
}
