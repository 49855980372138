<div class="gallery-container">
  <div class="gallery">
    <gallery-image-dialog (nextImageSelected)="loadNextImage()"
                          (previousImageSelected)="loadPreviousImage()"
                          (annotationUpdated)="updateAnnotation($event)"
                          (annotationConfirmed)="confirmAnnotation($event)"
                          (annotationRemoved)="removeAnnotation($event)"
                          (annotationCreated)="createAnnotation($event)"
                          [allowAnnotation]="allowAnnotation"
                          [showAnnotations]="showAnnotations"
                          [animals]="animals"
                          [tab]="tab"
                          [encounter]="encounter"
                          [showMetaData]="showMetaData"></gallery-image-dialog>
    <div class="gallery-sort-bar" *ngIf="showHeader">
      <div class="gallery-sort-bar-categories gallery-sort-bar-button-container">
        <h3>Sort Criteria: </h3>
        <button mat-button (click)="toggleSortCriteria(availableSortCriteria.creationDate)" [ngClass]="{'active': sortCriteria === availableSortCriteria.creationDate}">Creation Date</button>
        <button mat-button (click)="toggleSortCriteria(availableSortCriteria.uploadDate)" [ngClass]="{'active': sortCriteria === availableSortCriteria.uploadDate}">Upload Date</button>
        <button mat-button (click)="toggleSortCriteria(availableSortCriteria.photographer)" [ngClass]="{'active': sortCriteria === availableSortCriteria.photographer}">Photographer</button>
        <button mat-button (click)="toggleSortCriteria(availableSortCriteria.editor)" [ngClass]="{'active': sortCriteria === availableSortCriteria.editor}">Editor</button>
        <mat-slide-toggle [(ngModel)]="sortAscending" (toggleChange)="toggleSortDirection()">{{sortDirectionString}}</mat-slide-toggle>
      </div>
      <div class="view-mode-container">
        <mat-slide-toggle [(ngModel)]="continuousViewMode" (toggleChange)="toggleViewMode()" [labelPosition]="'before'">
          <mat-icon>{{viewModeIconString}}</mat-icon>
        </mat-slide-toggle>
      </div>
    </div>
    <div class="image-gallery" *ngIf="(images !== undefined && images !== null && images.length > 0) && this.continuousViewMode">
      <div class="image-gallery-item" *ngFor="let image of images">
        <div class="image">
          <div *ngIf="image.metaData !== undefined && image.metaData.thumbnail !== undefined">
            <img [src]="maybePrefix(image.metaData.thumbnail)" [alt]="image.name" loading="lazy" />
          </div>
          <div *ngIf="image.metaData !== undefined && image.metaData.thumbnail === undefined">
            <mat-icon>broken_image</mat-icon>
          </div>
          <div *ngIf="image.metaData === undefined">
            <img [src]="maybePrefix(image.image)" loading="lazy" />
          </div>
        </div>
        <div class="image-overlay" (click)="selectImage(image)" *ngIf="!markForDeletion">
          <div class="image-information shade" *ngIf="image.metaData !== undefined">
            <span *ngIf="getAnnotationHeader(image) !== undefined">{{getAnnotationHeader(image)}}</span>
            <span *ngFor="let line of getAnnotationOverlayLines(image)">{{line}}</span>
          </div>
        </div>
        <div class="action-overlay delete-overlay" *ngIf="markForDeletion" (click)="deleteImage(image)">
          <div class="action-icon delete-icon">
            <mat-icon>delete</mat-icon>
          </div>
        </div>
        <div class="action-overlay mark-overlay" *ngIf="markForSideLabel && !image.marked" (click)="markImage(image)">
          <div class="action-icon mark-icon">
            <mat-icon>plus</mat-icon>
          </div>
        </div>
        <div class="action-overlay mark-overlay" [ngClass]="{'marked': image.marked}" *ngIf="markForSideLabel && image.marked" (click)="markImage(image)">
          <div class="action-icon mark-icon">
            <mat-icon>check</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="image-gallery" *ngIf="(images !== undefined && images !== null && images.length > 0) && !this.continuousViewMode">
      <div *ngFor="let group of groupKeys" class="gallery-grid-section">
        <div class="gallery-grid-section-title">
          <h2 *ngIf="!sortByEncounter">{{group}}</h2>
          <h2 *ngIf="sortByEncounter">{{getHeadlineForEncounterGroup(groupedImages, group)}}</h2>
        </div>
        <div class="gallery-grid-section-content">
          <div class="image-gallery-item" *ngFor="let image of groupedImages.get(group)">
            <div class="image">
              <div *ngIf="image.metaData.thumbnail !== undefined">
                <img [src]="image.metaData.thumbnail" [alt]="image" loading="lazy"/>
              </div>
              <div *ngIf="image.metaData.thumbnail === undefined">
                <mat-icon>broken_image</mat-icon>
              </div>
            </div>
            <div class="image-overlay" (click)="selectImage(image)">
              <div class="image-information">
                <span *ngIf="getAnnotationHeader(image) !== undefined">{{getAnnotationHeader(image)}}</span>
                <span *ngFor="let line of getAnnotationOverlayLines(image)">{{line}}</span>
              </div>
<!--              <div class="overlay-footer">-->
<!--                <div class="overlay-footer-button">-->
<!--                  <span *ngIf="allowAnnotation">Add Annotations </span>-->
<!--                  <span *ngIf="!allowAnnotation">View Image</span>-->
<!--                  <span class="icon"><mat-icon>filter_center_focus</mat-icon></span>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>
    <div class="progress-indicator" *ngIf="(images === undefined) && !fetchComplete">
      <mat-progress-spinner mode="indeterminate" [strokeWidth]="2" [color]="'accent'"></mat-progress-spinner>
    </div>
  </div>

</div>

