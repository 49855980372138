import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {EncounterDto} from "../../../../../models/dto/encounter/encounterDto";
import {DateService} from "../../../../../services/utilities/date.service";
import {EncountersService} from "../../../../../services/encounters/encounters.service";
import {UserProfileDto} from "../../../../../models/dto/user/userProfileDto";
import {Observable, timer} from "rxjs";
import {IFileItem} from "../../../../../models/items/files/file-item.model";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {AnimalDto} from "../../../../../models/dto/animal/animalDto";
import {AnnotatedImage} from "../../../../../models/items/files/images/annotated-images/annotated-image.model";
import {UuidService} from "../../../../../services/utilities/uuid.service";
import {PopulationSettingsDto} from "../../../../../models/dto/population/populationSettingsDto";
import {WorkspaceDto} from "../../../../../models/dto/workspace/workspaceDto";
import {STATUS} from "../../../../../models/dto/status/statusChangeDto";
import {LicenseService} from "../../../../../services/license/license.service";
import {DomSanitizer} from "@angular/platform-browser";
import {AnimalProfileDto} from "../../../../../models/dto/animal/animalProfileDto";
import {AnimalService} from "../../../../../services/animal/animal.service";
import {PopulationDto} from "../../../../../models/dto/population/populationDto";


@Component({
  selector: 'app-single-encounter-container',
  templateUrl: './single-encounter-container.component.html',
  styleUrls: ['./single-encounter-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SingleEncounterContainerComponent implements OnInit, OnChanges {

  @Input() encounter: EncounterDto | undefined;
  @Input() populationSettings: PopulationSettingsDto | undefined;
  @Input() title: string | undefined;
  @Input() loadImages: boolean = true;
  @Input() tooltip: boolean = true;
  @Input() selectedImageId?: string | undefined;
  @Input() workspace: WorkspaceDto | undefined;
  @Output() encounterDeletedEmitter: EventEmitter<EncounterDto> = new EventEmitter<EncounterDto>();
  public user: UserProfileDto | undefined;
  public items: Array<IFileItem> | undefined;
  public suggestions: Array<AnimalDto> | undefined;
  public loading = false;
  public finishedLoading = false;
  public animals: Array<AnimalProfileDto> | undefined;
  public population: PopulationDto | undefined;
  // public userObs: Observable<UserProfileDto> | undefined;
  // public itemsObs: Observable<Array<IFileItem>> | undefined;
  // public suggestionObs: Observable<Array<AnimalDto>> | undefined;
  tooltipContent!: string;
  markForDeletion: boolean = false;
  markForSideLabel: boolean = false;
  animalObs: Observable<Array<AnimalDto>> | undefined;


  constructor(
    private dateService: DateService,
    private encounterService: EncountersService,
    private log: ILoggingService,
    private idService: UuidService,
    private licenseService: LicenseService,
    private sanitizer: DomSanitizer,
    private animalService: AnimalService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.encounter !== undefined && changes.encounter.previousValue !== undefined && changes.encounter.currentValue.id !== changes.encounter.previousValue.id) {
      this.ngOnInit();
    }
  }

  formatTime(dateTime: string | Date | undefined) {
    return this.dateService.formatDateFromAny(dateTime, false);
  }

  getTooltipContent(): void {
    if (this.encounter?.preview) {
      this.tooltipContent =
        ` ID: ${this.encounter.id}
          ${this.encounter?.preview.confirmedAnimalCount} known animals
          ${this.encounter?.preview.detectionCount} Animal Detections
          ${this.encounter?.preview.imageCount} Images
        `;
    }
  }
  isRecentUpload() {
    if (this.encounter && this.encounter.insertDateTime) {
      const insert = new Date(this.encounter.insertDateTime)
      const now = new Date();
      // @ts-ignore
      const diff = Math.abs(now - insert);
      return diff / (1000 * 60) < 20;
    }
    return false;
  }
  ngOnInit(): void {
    if (this.isRecentUpload()) {
      this.log.info("This encounter was recently uploaded. The images may not be completely finished processing and new ones may appear shortly.")
    }
    if (this.encounter === undefined || this.encounter.id === undefined) { return; }
    this.loadEncounter();
    if (this.workspace !== undefined && this.workspace.settings !== undefined) {
      this.population = this.workspace.settings.population
      this.animalObs = this.animalService.getAnimalPreviews(this.workspace.settings!.population!.id!, "all", true);
    }
  }

  sleep(timeMs: number): Promise<any> {
    return timer(timeMs).toPromise().then(res => {});
  }

  loadEncounter(): void {
    this.getTooltipContent();
    this.getItems();
    this.user = this.encounter?.user;
  }

  getSuggestions(): void {
    this.encounterService
      .getAnimalSuggestionsForEncounter(this.encounter!.id!)
      .subscribe(res => {
        this.suggestions = res;
      });
  }

  getItems(): void {
    this.items = undefined;
    this.finishedLoading = true;
    this.encounterService
      .getFileItemsForEncounter(this.encounter!.id!)
      .subscribe({
        next: async (value: Array<IFileItem>) => {
          this.items = value;
          await this.sleep(1000);
          this.getSuggestions();
          this.finishedLoading = false;
        }
      });
  }

  addImages($event: Array<IFileItem>) {
    if (this.items) {
      this.items.push(...$event.map( (val: IFileItem) => {
        const annotatedItem: AnnotatedImage = {
          annotations: [],
          populationId: val.populationId,
          id: val.id,
          encounterId: val.encounterId,
          name: val.name,
          metaData: {
            id: this.idService.generate(),
            // thumbnail: val.metaData.thumbnail
          },
          height: 0,
          width: 0,
          path: '',
          finishedLoading:  false
        }
        return annotatedItem
      }));
    }
  }

  fetchSuggestions() {
    this.getSuggestions();

  }

  encounterDeleted($event: EncounterDto) {
    this.encounterDeletedEmitter.emit($event);
  }

  getDisplayTab() {
    if (this.encounter?.status?.status == STATUS.FINISHED) {
      return 1;
    }
    return 0;
  }


  reload() {
    this.ngOnInit();
  }
}
