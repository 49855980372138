import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AnimalProfileDto} from "../../../../../models/dto/animal/animalProfileDto";
import {ILoggingService} from "../../../../../services/logging/logging.service.interface";
import {AnimalService} from "../../../../../services/animal/animal.service";
import {DateService} from "../../../../../services/utilities/date.service";
import {PopulationService} from "../../../../../services/population/population.service";
import {Observable} from "rxjs";
import {PopulationDto} from "../../../../../models/dto/population/populationDto";
import {HttpErrorResponse} from "@angular/common/http";
import {MatDatepicker} from "@angular/material/datepicker";
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { EncountersService } from '../../../../../services/encounters/encounters.service';
import {WorkspaceService} from "../../../../../services/workspace/workspace.service";


@Component({
  selector: 'app-profile-management',
  templateUrl: './profile-management.component.html',
  styleUrls: ['./profile-management.component.scss'],

})
export class ProfileManagementComponent implements OnInit {
  @Input() profile: AnimalProfileDto | undefined;
  @Output() loaded: EventEmitter<boolean> = new EventEmitter<boolean>();


  public userPermissionsObs: Observable<boolean> | undefined;
  public updatingDetails = false;
  public canUserChange: boolean = false;
  public population: PopulationDto | undefined;
  public profileDetailEdit = {
    'nickname': false,
    'sex': false,
    'dob': false,
    'dod': false,
    'age': false,
  }
  public allUserPopulations: Array<PopulationDto> = new Array<PopulationDto>();
  public selectablePopulations: Array<PopulationDto> = new Array<PopulationDto>();
  public inProgress = false;
  selectedPopulation: UntypedFormControl = new UntypedFormControl();


  constructor(
    private log: ILoggingService,
    private animalService: AnimalService,
    public dateService: DateService,
    private router: Router,
    private populationService: PopulationService,
    private workspaceService: WorkspaceService
  ) { }

  ngOnInit(): void {
    if (this.profile === undefined) { return; }

    this.populationService.getUserRightedPopulations().subscribe(res => {
      this.allUserPopulations = res;
      var allUserPopulations2 = res.map(obj => ({ ...obj }));
      this.populationService.population.subscribe(res => this.population = res);
      var index = allUserPopulations2.findIndex((element) => element.id == this.population!.id)
      allUserPopulations2.splice(index, 1);
      this.selectablePopulations = allUserPopulations2;
    })

    this.workspaceService.workspace.subscribe( res => {
      this.population = res.settings?.population;
      const roles = res.settings?.populationRoles;
      this.canUserChange = (roles?.expert || roles?.administrator || roles?.professional) ?? false;
    })
    if (typeof(this.profile.dateOfBirth) == "string") {
      this.profile.dateOfBirth = new Date(this.profile.dateOfBirth);
    }

    if (typeof(this.profile.dateOfDeath) == "string") {
      this.profile.dateOfDeath = new Date(this.profile.dateOfDeath);
    }
    // this.populationService.population.subscribe(res => {
    //   this.population = res
    //   if (this.population.id !== undefined) {
    //     this.populationService.populationRoles.subscribe(res => {
    //       this.canUserChange = (res !== undefined && (res.expert || res.professional || res.administrator))!;
    //     })
    //   }
    // });
    this.loaded.emit(true);


  }

  toggleEdit(field: string | undefined) {

    if (!this.canUserChange) {return;}
    let value = false;
    if (field !== undefined) {
      // @ts-ignore
      value = this.profileDetailEdit[field] as boolean;
    }
    // @ts-ignore
    if (this.profileDetailEdit[field!] == true) return;
    for (let key of Object.keys(this.profileDetailEdit)) {
      // @ts-ignore
      this.profileDetailEdit[key] = false;
    }
    if (field !== undefined){
      // @ts-ignore
      this.profileDetailEdit[field] = !value;
    }
  }

  leaveEdit() {
    if (!this.canUserChange) {return;}
    this.toggleEdit(undefined);
    this.updatingDetails = false;
    this.animalService
      .updateAnimalDetails(this.profile!)
      .subscribe({
        next: (value: AnimalProfileDto) => {
          this.log.info(`Profile Updated for ${value.identifier}`, true)
        },
        error: (err: HttpErrorResponse) => {
          this.log.error(`Could not update profile: ${err.error}`);
        }
      });
  }

  getAge() {
    if (this.profile?.dateOfBirth === undefined) {return 'Unknown'}
    let dob = this.profile.dateOfBirth;
    if (typeof this.profile.dateOfBirth === "string") {
      dob = new Date(this.profile.dateOfBirth);
    }
    let endDate = Date.now();
    if (this.profile.dateOfDeath) {
      // @ts-ignore
      endDate = this.profile.dateOfDeath;
      if (typeof this.profile.dateOfDeath === "string") {
        // @ts-ignore
        endDate = new Date(this.profile.dateOfDeath);
      }
    }



    // @ts-ignore
    const difference = endDate - dob;
    const ageDate = new Date(difference);
    const age = Math.abs(ageDate.getUTCFullYear() - 1970);
    if (age === 0) {
      return '< 1'
    }
    return age;
  }

  displayBirthday() {
    return this.dateService.formatDateFromAny(this.profile!.dateOfBirth, false, true, false, false, false)
  }

  displayDeathday() {
    return this.dateService.formatDateFromAny(this.profile!.dateOfDeath, false, true, false, false, false)

  }

  updateDob($event: Date) {
    this.profile!.dateOfBirth = $event;
  }

  updateDod($event: Date) {
    this.profile!.dateOfDeath = $event;
  }

  updateProfile() {
    this.updatingDetails = false;
  }

  startProfileUpdate() {
    this.updatingDetails = true;
    this.profileDetailEdit.dob = true;
    this.profileDetailEdit.dod = true;
    this.profileDetailEdit.sex = true;
  }

  cancelProfileUpdate() {
    this.updatingDetails = false;
    this.profileDetailEdit.dob = false;
    this.profileDetailEdit.dod = false;
    this.profileDetailEdit.sex = false;
  }

  updatePopulation() {
    if (this.profile == null || this.profile.id == null) {
      return;
    }
    this.inProgress = true;
    this.profile!.populationId = this.selectedPopulation.value;

    this.animalService.changePopulation(this.profile!.id!, this.selectedPopulation.value).subscribe();
    this.populationService.getPopulationById(this.selectedPopulation.value).subscribe(res => {
      this.population = res;
      var allUserPopulations2 = this.allUserPopulations.map(obj => ({ ...obj }));
      var index = allUserPopulations2.findIndex((element) => element.id == this.population!.id)
      allUserPopulations2.splice(index, 1);
      this.selectablePopulations = allUserPopulations2;
      this.inProgress = false;
    });
  }


}
