<mat-drawer-container>
  <mat-drawer #encounterSelectionDrawer [mode]="getMode()" [opened]="!isMobile || (isMobile && sidebarOpen)" [position]="'end'" (closed)="sidebarOpen=false"  class="encounter-select-drawer" [style.width.px]="310">
    <div class="encounter-select-drawer-content" *ngIf="request !== undefined">
      <app-encounter-selection-drawer [request]="request" [animals]="animals" (requestUpdated)="setRequest($event)"></app-encounter-selection-drawer>
    </div>
  </mat-drawer>
  <mat-drawer-content [style.min-height.vh]="95">
    <div class="content-container">
      <div class="content">
        <div class="content-header">
          <div class="sidebar-toggle" *ngIf="isMobile">
            <button mat-raised-button color="accent" (click)="sidebarOpen = !sidebarOpen">Search</button>
          </div>
        </div>

        <div class="content-body">
          <div class="view-header">
            <div class="view-toggle">
              <mat-button-toggle-group name="view" aria-label="View" [(ngModel)]="view" (ngModelChange)="switchView()">
                <mat-button-toggle value="grid" checked>List View</mat-button-toggle>
                <mat-button-toggle value="map">Map View</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div class="view-selection" *ngIf="request !== undefined">
              <mat-form-field color="accent" *ngIf="view === 'grid'">
                <mat-label>Encounter Limit</mat-label>
                <mat-select [(ngModel)]="encounterLimitString" (ngModelChange)="updateLimit($event)">
                  <mat-option *ngFor="let size of selectionSizes" [value]="size">{{size}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field color="accent" *ngIf="view === 'map'">
                <mat-label>Encounter Limit </mat-label>
                <mat-select [(ngModel)]="encounterLimitString" (ngModelChange)="updateLimit($event)">
                  <mat-option *ngFor="let size of mapSizes" [value]="size">{{size}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>


          <div class="grid-selection" *ngIf="view =='grid'">

            <div class="content">
              <div class="encounter-grid" *ngIf="encounters">
                <div class="encounter" *ngFor="let encounter of encounters">
                  <app-encounter-preview [encounter]="encounter" [request]="request"></app-encounter-preview>
                </div>

              </div>



            </div>
            <!--              <div class="empty-content" *ngIf="!encounters || encounters.length === 0">-->
            <!--                <h2>No Encounters Found</h2>-->
            <!--              </div>-->
            <!--            <div class="load-next-button" (click)="loadNext()" *ngIf="nextResponse !== undefined && encounters != null">-->
            <!--              <h2> Load Next </h2>-->
            <!--            </div>-->
            <div class="empty-content">
              <h2 *ngIf="(!encounters || encounters.length === 0) && searchFinished"> No Encounters Found</h2>
            </div>
          </div>
          <div class="map-selection" *ngIf="view == 'map'">
            <div *ngIf="encounters">
              <events-map [encounters]="encounters"></events-map>
            </div>
          </div>




        </div>
      </div>
 

      <div class="page-ctrl-container" *ngIf="request !== undefined">
        <div class="page-ctrl-child" >
          <button mat-icon-button (click)="firstPage()" [disabled]="isFirstPageDisabled()"><mat-icon>first_page</mat-icon></button>
          <button mat-icon-button (click)="previousPage()" [disabled]="isPreviousPageDisabled()"><mat-icon>chevron_left</mat-icon></button>

          <button mat-icon-button (click)="nextPage()" [disabled]="isNextPageDisabled()"><mat-icon>chevron_right</mat-icon></button>
          <button mat-icon-button (click)="lastPage()" [disabled]="isLastPageDisabled()"><mat-icon>last_page</mat-icon></button>
        </div>
      </div>

    </div>
  </mat-drawer-content>
</mat-drawer-container>


<div class="loading-next">
  <app-loading-overlay [criteria]="nextRequested" [title]="'Fetching Encounters...'"></app-loading-overlay>
</div>



