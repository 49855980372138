
<mat-drawer-container class="example-container" autosize>
  <mat-drawer #drawer class="example-sidenav" mode="over" [(opened)]="drawerOpen" position="end">
    <ng-container *ngIf="activeModule === 'roleUpdate'">
      <ng-container *ngTemplateOutlet="roleUpdateContent"></ng-container>
    </ng-container>


  </mat-drawer>
  <mat-drawer-content>
    <div class="content-container">
      <div class="content" *ngIf="users !== undefined">


        <div class="content-header">
          <div class="title">
            <h2>Users</h2>
          </div>
        </div>
        <button mat-button (click)="createUser()"><mat-icon>add</mat-icon>Create</button>
        <mat-divider></mat-divider>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef>First Name</th>
            <td mat-cell *matCellDef="let element">{{element.firstName}} </td>
          </ng-container>
          <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef>Last Name</th>
            <td mat-cell *matCellDef="let element">
              {{element.lastName}} </td>
          </ng-container>
          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
              <a [routerLink]="getProfileLink(element)">{{element.email}} </a> </td>
          </ng-container>
          <ng-container matColumnDef="encounters">
            <th mat-header-cell *matHeaderCellDef>Encounters</th>
            <td mat-cell *matCellDef="let element">{{element.userStatistics.encounters}}</td>
          </ng-container>
          <ng-container matColumnDef="images">
            <th mat-header-cell *matHeaderCellDef>Images</th>
            <td mat-cell *matCellDef="let element">{{element.userStatistics.images}}</td>
          </ng-container>
          <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef>System Role</th>
            <td mat-cell *matCellDef="let element">{{element.roles}}</td>
          </ng-container>
          <ng-container matColumnDef="update">
            <th mat-header-cell *matHeaderCellDef>Update</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="doRoleUpdate(element)"><mat-icon>edit</mat-icon></button>
            </td>
          </ng-container>
          <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button (click)="deleteUser(element)"><mat-icon>delete</mat-icon></button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>


<app-area-loading [criteria]="users !== undefined" [title]="'Fetching Users...'"></app-area-loading>
<app-area-loading [criteria]="!saving" title="Saving..."></app-area-loading>

<ng-template #roleUpdateContent>
  <div class="content" *ngIf="activeElement !== undefined">
    <div class="content-header">
      <h3>Update User Role</h3>
      <h4>{{activeElement.firstName}} {{activeElement.lastName}}</h4>
    </div>

    <mat-select *ngIf="activeElement" [(ngModel)]="activeElement!.systemRole">
        <mat-option *ngFor="let role of roles" [value]="role">{{role}}</mat-option>
    </mat-select>

    <div class="button-row">
      <button mat-button (click)="drawer.close()">Cancel</button>
      <button mat-button (click)="saveRoleUpdate(activeElement!)">Save</button>
    </div>

  </div>
</ng-template>
