<div class="content-container">
  <div class="content">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef>Display Name</th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="getLink(element)">{{element.displayName}} </a> </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="speciesName">
        <th mat-header-cell *matHeaderCellDef> Species </th>
        <td mat-cell *matCellDef="let element"> {{element.speciesName}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>

<ng-template #loading>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="25"  [color]="'accent'"></mat-progress-spinner>
</ng-template>
