<mat-drawer-container class="editing-sidebar">
  <mat-drawer mode="over" #drawer position="end" class="editing-sidebar-drawer-content">
    <ng-container *ngIf="activeModule === 'updateAnimal'">
      <ng-container *ngTemplateOutlet="updateAnimalContent"></ng-container>
    </ng-container>

    <!--    <ng-container *ngIf="activeModule === 'updatingBehavior'">-->
    <!--      <ng-container *ngTemplateOutlet="behaviorUpdateContent"></ng-container>-->
    <!--    </ng-container>-->
  </mat-drawer>
  <mat-drawer-content>
    <div class="button-row">
      <button mat-button (click)="doStartNew()"><mat-icon>add</mat-icon>Create</button>
      <div class="table-filter">
        <mat-form-field color="accent">
          <mat-label>Filter Individuals</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Enter name or ID" />
        </mat-form-field>
      </div>
    </div>
    <mat-divider></mat-divider>

  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>Profile</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [routerLink]="getProfileLink(element)"><mat-icon>link</mat-icon> </button> </td>

    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="identifier">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Identifier</th>
      <td mat-cell *matCellDef="let element">
        <a (click)="doUpdate(element)">{{element.primaryIdentifier}}</a></td>
    </ng-container>

    <ng-container matColumnDef="alternateIdentifiers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Alternate Identifiers</th>
      <td mat-cell *matCellDef="let element">{{element.alternateIds?.join(' / ')}}</td>
    </ng-container>

    <ng-container matColumnDef="sex">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sex</th>
      <td mat-cell *matCellDef="let element">{{element.sex}}</td>
    </ng-container>

    <ng-container matColumnDef="birthYear">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header>Birth Year</th>
      <td mat-cell *matCellDef="let element">{{dateService.formatDateFromAny(element.dateOfBirth, false, true, false, false, false)}}</td>
    </ng-container>

    <ng-container matColumnDef="deathYear">
      <th mat-header-cell *matHeaderCellDef  mat-sort-header>Max Year of Death</th>
      <td mat-cell *matCellDef="let element">{{dateService.formatDateFromAny(element.dateOfDeath, false, true, false, false, false)}}</td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef>Delete</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="deleteAnimal(element)"><mat-icon>delete</mat-icon></button>
      </td>Creating a new job to export image data
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator #paginator [pageSizeOptions]="[10, 25, 50, 100, 500]" pageSize="25"
                 showFirstLastButtons
                 aria-label="Select page of periodic elements">
  </mat-paginator>
  </mat-drawer-content>
</mat-drawer-container>

<app-area-loading [criteria]="animals" title="Fetching Individuals"></app-area-loading>


<ng-template #updateAnimalContent>
  <div class="content" *ngIf="activeElement !== undefined">
    <h3>Update {{activeElement.identifier}}</h3>

    <div class="content-section">
      <h4>Identification</h4>
      <mat-form-field color="accent">
        <mat-label>Primary Identifier</mat-label>
        <input matInput [(ngModel)]="activeElement.primaryIdentifier" />

      </mat-form-field>
    </div>

    <div class="alternate-ids">
      <h4>Alternate IDs</h4>
      <button mat-button color="accent" (click)="addId()" type="button"><mat-icon>add</mat-icon>Add New ID</button>
      <form [formGroup]="form">
        <div formArrayName="alternateIds">
          <div *ngFor="let id of alternateIds.controls; let i = index" [formGroupName]="i" class="id-group">
            <mat-form-field class="id-input"  color="accent">
              <mat-label>Alternate Identifier {{ i + 1 }}</mat-label>
              <input matInput formControlName="value" />
            </mat-form-field>
            <button mat-icon-button color="warn" (click)="removeId(i)" aria-label="Remove ID">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>

      </form>
    </div>

    <div class="content-section">
      <h4>Descriptors</h4>
      <mat-button-toggle-group [(ngModel)]="activeElement.sex">
        <mat-button-toggle [value]="'Male'">Male</mat-button-toggle>
        <mat-button-toggle [value]="'Female'">Female</mat-button-toggle>
        <mat-button-toggle [value]="undefined">Unknown</mat-button-toggle>
      </mat-button-toggle-group>
    </div>



    <mat-divider></mat-divider>


    <div class="birth-selection content-section">
      <h4>Birth Month and Year</h4>
      <mat-form-field class="month-select" color="accent">
        <mat-label>Month</mat-label>
        <mat-select [(ngModel)]="activeElement.birthMonth">
          <mat-option *ngFor="let month of dateService.months" [value]="month.value">
            {{ month.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="year-select" color="accent">
        <mat-label>Year</mat-label>
        <mat-select [(ngModel)]="activeElement.birthYear">
          <mat-option *ngFor="let year of years" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="birth-selection content-section">
      <h4>Death Month and Year</h4>
      <mat-form-field class="month-select" color="accent">
        <mat-label>Month</mat-label>
        <mat-select [(ngModel)]="activeElement.deathMonth">
          <mat-option *ngFor="let month of dateService.months" [value]="month.value">
            {{ month.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="year-select" color="accent">
        <mat-label>Year</mat-label>
        <mat-select [(ngModel)]="activeElement.deathYear">
          <mat-option *ngFor="let year of years" [value]="year">
            {{ year }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="button-row">
      <button mat-button (click)="drawer.close()">Cancel</button>
      <button mat-button (click)="save(activeElement)">Save</button>
    </div>

  </div>
</ng-template>
