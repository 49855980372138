<mat-drawer-container class="editing-sidebar">
  <mat-drawer mode="over" #drawer position="end" class="editing-sidebar-drawer-content">
    <ng-container *ngIf="activeModule === 'newRequest'">
      <ng-container *ngTemplateOutlet="newRequestContent"></ng-container>
    </ng-container>

<!--    <ng-container *ngIf="activeModule === 'updatingBehavior'">-->
<!--      <ng-container *ngTemplateOutlet="behaviorUpdateContent"></ng-container>-->
<!--    </ng-container>-->
  </mat-drawer>
  <mat-drawer-content>
    <div class="content-container">
      <div class="content-header">
        <button mat-button (click)="startExportRequest()"><mat-icon>add</mat-icon>Create Request</button>
      </div>
      <mat-divider></mat-divider>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource">

          <!-- Position Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element"> {{element.name}} </td>
          </ng-container>

          <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef>From</th>
            <td mat-cell *matCellDef="let element"> {{element.from}} </td>
          </ng-container>

          <ng-container matColumnDef="to">
            <th mat-header-cell *matHeaderCellDef>To</th>
            <td mat-cell *matCellDef="let element"> {{element.to}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]"
                       showFirstLastButtons
                       aria-label="Select page of population behavior">
        </mat-paginator>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>


<ng-template #newRequestContent>
  <div class="content" *ngIf="activeElement !== undefined">
    <h4>Creating a new job to export image data</h4>
    <mat-form-field color="accent">
      <mat-label>From</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="activeElement.from">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field color="accent">
      <mat-label>To</mat-label>
      <input matInput [matDatepicker]="toPicker" [(ngModel)]="activeElement.to">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="toPicker"></mat-datepicker-toggle>
      <mat-datepicker #toPicker></mat-datepicker>
    </mat-form-field>

    <mat-checkbox  color="accent" [(ngModel)]="activeElement.encounterStructure">Keep Encounter Structure</mat-checkbox>
    <mat-checkbox  color="accent" [(ngModel)]="activeElement.includeAttribution">Include Data Which Requires Attribution</mat-checkbox>



    <div class="button-row">
      <button mat-button (click)="drawer.close()">Cancel</button>
      <button mat-button (click)="submit()">Submit Request</button>
    </div>

  </div>
</ng-template>


